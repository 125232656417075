import { HtmlToTextOptions } from 'html-to-text';
import {
  IAccountRoleV2,
  IAccountSource,
  IEmailUnsubscribe,
  IRepairStatus,
  TRole,
  TVoteType,
} from './types';

export const FIRESTORE_ARRAY_CONTAINS_ANY_LIMIT: number = 30;
export const RESERVATION_DAYS_LIMIT: number = 30;
export const MAX_RECENTLY_ADDED_DOCUMENTS: number = 30;

export const HTML2TEXT_DEFAULT_OPTIONS: HtmlToTextOptions = {
  preserveNewlines: true,
  wordwrap: false,
  formatters: {
    zeroLinesFormatter: function (elem, walk, builder) {
      builder.openBlock({ leadingLineBreaks: 0 });
      walk(elem.children, builder);
      builder.closeBlock({ trailingLineBreaks: 0 });
    },
    singleLinesFormatter: function (elem, walk, builder) {
      builder.openBlock({ leadingLineBreaks: 0 });
      walk(elem.children, builder);
      builder.closeBlock({ trailingLineBreaks: 1 });
    },
  },
  selectors: [
    {
      selector: 'h1',
      format: 'singleLinesFormatter',
    },
    {
      selector: 'h2',
      format: 'singleLinesFormatter',
    },
    {
      selector: 'h3',
      format: 'singleLinesFormatter',
    },
    {
      selector: 'p',
      format: 'singleLinesFormatter',
    },
    {
      selector: 'ol',
      format: 'singleLinesFormatter',
    },
    {
      selector: 'ul',
      format: 'singleLinesFormatter',
    },
    {
      selector: 'br',
      format: 'zeroLinesFormatter',
    },
  ],
};

export const TECH_SUPPORT_EMAIL = 'tech@immodigi.ch';
export const CUSTOMER_SUPPORT_EMAIL = 'support@immodigi.ch';
export const AANDARTA_SALES_EMAIL = 'vanja.draca@aandarta.ch';

export enum FUNCTION_LOCATION {
  'europe-west1' = 'europe-west1',
  'europe-west6' = 'europe-west6',
}

export enum CMD_COLOR {
  black = '\x1b[30m%s\x1b[0m',
  red = '\x1b[31m%s\x1b[0m',
  green = '\x1b[32m%s\x1b[0m',
  yellow = '\x1b[33m%s\x1b[0m',
  blue = '\x1b[34m%s\x1b[0m',
  magenta = '\x1b[35m%s\x1b[0m',
  cyan = '\x1b[36m%s\x1b[0m',
  white = '\x1b[37m%s\x1b[0m',
}

export const DEFAULT_LANGUAGE_ID = 'de';
export const DEFAULT_LANGUAGE_ID_PDFVIEWER = 'de';
export const EMAIL_TRACKING_HEADER_ID = 'X-Firestore-ID';
export const EMAIL_TRACKING_API_URL =
  'https://api.smtp2go.com/v3/activity/search';
export const EMAIL_TRACKING_API_KEY = 'api-480F6C723FEA4A7497ADF4AFE4FEA8F3'; // Note: This only has access to get stats. Access to anything else is forbidden.

export const ERP_SYSTEM = Object.freeze({
  abaimmo: 'abaimmo',
  immotop: 'immotop',
  rimo: 'rimo',
  excel: 'excel',
  none: 'none',
});

export const FILE_SOURCE = Object.freeze({
  abaimmo: 'abaimmo',
  rclone: 'rclone',
  syncme: 'syncme',
  none: 'none',
});

export const SENTRY_DNS =
  'https://f7da0cde40c84cb9bf7dd1445279c9ba@o274448.ingest.sentry.io/6522911';

export const DOCUMENTS_MAX_SEARCH_QUERY_RESULTS = 40;
export const DOCUMENTS_MAX_SEARCH_DISPLAY_RESULTS = 20;
export const MIN_SEARCH_CHARACTERS = 3;

export const EXTERNAL_FORM_MANDATE_ID = -1;

export const emailRegex = new RegExp(
  /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/,
);

export const ID = {
  case_number: 'case_number',
  abaimmo: 'abaimmo',
  mandate_data_v2: 'mandate_data_v2',
  is_public: 'is_public',
  key_quantity: 'key_quantity',
  key_label: 'key_label',
  nameplate_row1: 'nameplate_row1',
  nameplate_row2: 'nameplate_row2',
  location_details: 'location_details',
  serial_number: 'serial_number',
  manufacturer: 'manufacturer',
  dataentryist_name: 'dataentryist_name',
  property: 'property',
  reservation_place: 'reservation_place',
  reservation_day: 'reservation_day',
  reservation_booking_start: 'reservation_booking_start',
  reservation_booking_end: 'reservation_booking_end',
  address_street: 'address_street',
  address_zip: 'address_zip',
  address_city: 'address_city',
  contact_phone: 'contact_phone',
  contact_email: 'contact_email',
  contact_name_first: 'contact_name_first',
  contact_name_last: 'contact_name_last',
  user: 'user',
  role: 'role',
  role_highest: 'role_highest',
  mandate_ids: 'mandate_ids',
  uid: 'uid',
  push_denied: 'push_denied',
  warrant_receiver: 'warrant_receiver',
  endStroke: 'endStroke',
  functions: 'functions',
  votes: 'votes',
  last_name_ci: 'last_name_ci',
  last_name: 'last_name',
  documents: 'documents',
  tenant_admin: 'tenant-admin',
  title: 'title',
  description: 'description',
  privacy_policy: 'privacy_policy',
  captcha: 'captcha',
  insert: 'insert',
  form_guide: 'form_guide',
  insert_auth: 'insert_auth',
  update: 'update',
  confirm: 'confirm',
  resend_access: 'resend_access',
  personal_limits: 'personal_limits',
  photo_profile: 'photo_profile',
  meetings: 'meetings',
  questionnaires: 'questionnaires',
  mandate_id: 'mandate_id',
  property_id: 'property_id',
  entry_category: 'entry_category',
  entry_id: 'entry_id',
  comment_id: 'comment_id',
  tab_name: 'tab_name',
  message: 'message',
  ticket: 'ticket',
  repair: 'repair',
  keyorder: 'keyorder',
  pinboard: 'pinboard',
  voteitem: 'voteitem',
  voteitems: 'voteitems',
  push_status: 'push_status',
  push_status_reminder: 'push_status_reminder',
  email_status_reminder: 'email_status_reminder',
  date_creation: 'date_creation',
  date_start: 'date_start',
  email: 'email',
  email_status: 'email_status',
  email_external: 'email_external',
  email_status_external: 'email_status_external',
  vote_status: 'vote_status',
  vote_type: 'vote_type',
  yarowa_service_staging: 'yarowa_service_staging',
  yarowa_service_production: 'yarowa_service_production',
  other: 'other',
};

export enum PUSH_CHECK_TYPE {
  on_create = 'on_create',
  on_update = 'on_update',
  scheduled = 'scheduled',
}

export const PUSH_STATUS = Object.freeze({
  error: {
    intl_id: 'GLOBAL.ERROR',
    value: -1,
  },
  no_push: {
    intl_id: 'messages.pushStatusNoPush',
    value: 0,
  },
  scheduled: {
    intl_id: 'global.scheduled',
    value: 1,
  },
  sending_out: {
    intl_id: 'messages.pushStatusSendingOut',
    value: 2,
  },
  sent_out: {
    intl_id: 'messages.pushStatusSentOut',
    value: 3,
  },
});

export const PDF_FONT_HELVETICA = {
  Helvetica: {
    normal: 'Helvetica',
    bold: 'Helvetica-Bold',
    italics: 'Helvetica-Oblique',
    bolditalics: 'Helvetica-BoldOblique',
  },
};

export const SECRETS = Object.freeze({
  access_token: 'UJZqnOzB2kCUpGzgCo3tlvqqxRgnQh4T',
  cryptr_secret: 'M7kPnqa9tYUO2lR6ctJWHpwQx7Ks0rP',
});

export const SECRET_ID = Object.freeze({
  abaimmo_client: 'abaimmo_client',
  sm_immo_top_1: 'sm_immo_top_1',
  sm_immo_top_2: 'sm_immo_top_2',
  sm_jwt: 'sm_jwt',
});

export const USER_VOTETYPE: Record<
  TVoteType,
  { value: string; intl_id: string }
> = {
  live: {
    value: 'live',
    intl_id: 'votes.live',
  },
  online: {
    value: 'online',
    intl_id: 'votes.online',
  },
  manual: {
    value: 'manual',
    intl_id: 'votes.manuallyAdded',
  },
  warrant: {
    value: 'warrant',
    intl_id: 'votes.warrant',
  },
};

export const VOTEITEM_LIVE_STATUS = {
  available: {
    value: 'available',
    intl_id: 'votes.setAvailable',
  },
  paused: {
    value: 'paused',
    intl_id: 'votes.setPaused',
  },
  completed: {
    value: 'completed',
    intl_id: 'votes.setCompleted',
  },
};

/** https://developer.mozilla.org/en-US/docs/Web/HTTP/Status */
export enum HTTP_STATUS_CODE {
  ok = 200,
  bad_request = 400,
  unauthorized = 401,
  too_many_requests = 429,
  internal = 500,
}

export enum ERROR_CODE_CF {
  ok = 'ok',
  cancelled = 'cancelled',
  unknown = 'unknown',
  invalid_argument = 'invalid-argument',
  deadline_exceeded = 'deadline-exceeded',
  not_found = 'not-found',
  already_exists = 'already-exists',
  permission_denied = 'permission-denied',
  resource_exhausted = 'resource-exhausted',
  failed_precondition = 'failed-precondition',
  aborted = 'aborted',
  out_of_range = 'out-of-range',
  unimplemented = 'unimplemented',
  internal = 'internal',
  unavailable = 'unavailable',
  data_loss = 'data-loss',
  unauthenticated = 'unauthenticated',
  auth_wrong_password = 'auth/wrong-password',
  auth_too_many_requests = 'auth/too-many-requests',
  storage_object_not_found = 'storage/object-not-found',
  storage_canceled = 'storage/canceled',
  access_code_invalid = 'access-code-invalid',
}

export enum ERROR_CODE_OTHER {
  auth_wrong_password = 'auth/wrong-password',
  auth_invalid_credential = 'auth/invalid-credential',
  auth_user_not_found = 'auth/user-not-found',
  auth_invalid_email = 'auth/invalid-email',
  auth_weak_password = 'auth/weak-password',
  auth_email_exists = 'auth/email-already-exists',
  auth_email_already_in_use = 'auth/email-already-in-use',
  auth_email_not_defined = 'auth/email-not-defined',
  auth_too_many_requests = 'auth/too-many-requests',
  storage_object_not_found = 'storage/object-not-found',
  storage_canceled = 'storage/canceled',
  access_code_invalid = 'access-code-invalid',
}

export const ERROR_CODE = { ...ERROR_CODE_CF, ...ERROR_CODE_OTHER };

export enum MESSAGE_SCOPE {
  all = 'all',
  internal = 'internal',
  external = 'external',
}

export enum YAROWA_PROCESS_STATUS {
  initiated,
  pending,
  created,
  accepted,
  completed,
}

export enum SPACE_SELECTION_SCREEN {
  'signin',
  'forgotpassword',
}

export enum SPACE_FUNCTIONS {
  gl_account_delete_request = 'gl_account_delete_request',
  gl_account_reactivate_request = 'gl_account_reactivate_request',
  gl_encrypt = 'gl_encrypt',
  gl_email_test = 'gl_email_test',
  gl_example_mandate = 'gl_example_mandate',
  gl_verify_email = 'gl_verify_email',
  gl_staff_created = 'gl_staff_created', // TODO rename to gl_send_invite_mail eventually
  gl_update_remote_config = 'gl_update_remote_config',
  gl_sync_documents = 'gl_sync_documents',
  gl_reset_data = 'gl_reset_data',
  gl_create_users_from_persons = 'gl_create_users_from_persons',
  gl_excel_import = 'gl_excel_import',
  gl_security_notification = 'gl_security_notification',
  gl_write_auth_account = 'gl_write_auth_account',
  gl_check_rate_limit = 'gl_check_rate_limit',
  ia_get_report_receivers = 'ia_get_report_receivers',
  ia_external_email_preview = 'ia_external_email_preview',
  ia_handyman_email_preview = 'ia_handyman_email_preview',
  ia_pdf_introduction = 'ia_pdf_introduction',
  ia_pdf_vote_results_user = 'ia_pdf_vote_results_user/doc',
  ia_pdf_vote_protocol = 'ia_pdf_vote_protocol/doc',
  ia_doc_vote_protocol = 'ia_doc_vote_protocol/doc',
  ia_pdf_vote_results_overview = 'ia_pdf_vote_results_overview/doc',
  ia_create_comment = 'ia_create_comment',
  ia_create_report = 'ia_create_report',
  ia_web_push_topics = 'ia_web_push_topics',
  ia_resend_access = 'ia_resend_access',
  ia_tenant_resend_invite = 'ia_tenant_resend_invite',
  ia_tenant_terminate = 'ia_tenant_terminate',
  ia_share_report_via_email_v2 = 'ia_share_report_via_email_v2',
  if_calculation_immovable = 'if_calculation_immovable',
  if_calculation_component = 'if_calculation_component',
  if_delete_immovable = 'if_delete_immovable',
  if_duplicate_immovable = 'if_duplicate_immovable',
  if_pdf_report = 'if_pdf_report/doc',
  im_tenant_created = 'im_tenant_created',
  im_resend_access_code = 'im_resend_access_code',
  im_send_voucher = 'im_send_voucher',
  vote_update = 'vote_update',
  vote_register = 'vote_register',
  vote_redeem_code = 'vote_redeem_code',
  vote_resend_code = 'vote_resend_code',
  vote_submit = 'vote_submit',
}

export enum CONTROL_FUNCTIONS {
  ic_get_spaces_for_user = 'ic_get_spaces_for_user',
  ic_get_space_by_id = 'ic_get_space_by_id',
  ic_register_user = 'ic_register_user',
  ic_register_tenant = 'ic_register_tenant',
  ic_password_reset_mail = 'ic_password_reset_mail',
  ic_delete_user = 'ic_delete_user',
  ic_change_email = 'ic_change_email',
  ic_check_email_availability = 'ic_check_email_availability',
  ic_get_constants = 'ic_get_constants',
}

export enum URLS {
  store_immoapp_ios = 'https://apps.apple.com/de/app/immoapp/id1493436309',
  store_immoapp_android = 'https://play.google.com/store/apps/details?id=ch.immodigi.immoapp',
  store_aebi_ios = 'https://apps.apple.com/de/app/aebi-immo/id1470674410',
  store_aebi_android = 'https://play.google.com/store/apps/details?id=ch.peteraebi.aebiimmo',
  google_drive_viewer = 'https://docs.google.com/viewer?embedded=true&url=',
  control_cloud = 'https://europe-west1-immodigi-control.cloudfunctions.net',
  web_app_default = 'https://app.immodigi.ch',
}

export enum PUSH_TOPICS {
  push_document = 'push_document',
  push_mandate = 'push_mandate',
  push_reservation = 'push_reservation',
  push_pinboard = 'push_pinboard',
  push_vote = 'push_vote',
  push_staff = 'push_staff',
}

export enum EMAIL_TOPICS {
  email_comment = 'email_comment',
  email_pinboard = 'email_pinboard',
  email_message = 'email_message',
  email_external = 'email_external',
  email_reservation = 'email_reservation',
  email_repair = 'email_repair',
  email_repair_update = 'email_repair_update',
  email_voteitem = 'email_voteitem',
}

export enum EMAIL_TRACKING_SUFFIX {
  main = 'main',
}

export enum DB {
  // Collections
  settings = 'settings',
  stats = 'stats',
  ia_mandates = 'ia_mandates',
  ia_properties = 'ia_properties',
  ia_objects = 'ia_objects',
  ia_documents = 'ia_documents',
  ia_messages = 'ia_messages',
  ia_repairs = 'ia_repairs',
  ia_pinboard = 'ia_pinboard',
  ia_votes = 'ia_votes',
  ia_persons = 'ia_persons',
  ia_users = 'ia_users',
  ia_db_files_folder = 'ia_db_files_folder',
  ia_db_root_folders = 'ia_db_root_folders',
  if_immovables = 'if_immovables',
  if_components = 'if_components',
  if_bank_accounts = 'if_bank_accounts',
  im_termination_process = 'im_termination_process',
  im_files_common = 'im_files_common',
  infocenter = 'infocenter',
  sm_imports = 'sm_imports',
  sm_settings = 'sm_settings',
  im_stats = 'im_stats',
  ic_spaces = 'ic_spaces',
  ic_users = 'ic_users',
  // Subcollections
  sub_email_delivery_report_details = 'email_delivery_report_details',
  sub_emails = 'emails',
  sub_push_messages = 'push_messages',
  sub_users = 'users',
  sub_items = 'items',
  // Documents
  doc_communication = 'communication',
  doc_users = 'users',
  doc_content = 'content',
  doc_license = 'license',
  doc_common_settings = 'common_settings',
  doc_filemanager = 'filemanager',
  doc_files_common = 'files_common',
  doc_external_form = 'external_form',
  doc_administration = 'administration',
  doc_documents = 'documents',
  doc_faqs = 'faqs',
  doc_object = 'object',
  doc_total = 'total',
  doc_db_sync = 'db_sync',
  doc_rclone = 'rclone',
  // Fields
  field_comments = 'comments',
  field_default_access_group = 'default_access_group',
}

export enum DAY {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

export enum REMOTE_CONFIG {
  accent_color = 'accent_color',
  client_info = 'client_info',
  min_version_v2 = 'min_version_v2',
  erp_system = 'erp_system',
  repair_public_default = 'repair_public_default',
  energy_url = 'energy_url',
  access_comments = 'access_comments',
  access_pinboard = 'access_pinboard',
  access_tenantadmin = 'access_tenantadmin',
  access_voting = 'access_voting',
  access_voteitem_submit = 'access_voteitem_submit',
  access_documents = 'access_documents',
  access_infocenter = 'access_infocenter',
  show_order_immoapp = 'show_order_immoapp',
  show_otheritem = 'show_otheritem',
  show_neighbour_toggle = 'show_neighbour_toggle',
}

export enum STORAGE {
  settings = 'Settings',
  client_logo = 'logo.png',
  masterdata = 'stammdaten.xlsx',
  repair_files = 'Repair_files',
  pinboard_files = 'Pinboard_files',
  document_files = 'Document_files',
  infocenter_files = 'Infocenter_files',
  voting_files = 'Voting_files',
  user_files = 'User_files',
  articles_images = 'Article_images',

  database_files = 'Database_files',
  message_files = 'Message_files',
  mandate_images = 'Mandate_images',
  immovable_image = 'if_immovable',
  component_image = 'if_component',
  immomove_files_process = 'im_files_process',
  immomove_files_common = 'im_files_common',

  database_backup = 'database_backup',
  accessfiles_backup = 'accessfiles_backup',
  users_backup = 'users_backup',
  storage_backup = 'storage_backup',
}

export enum STORAGE_META {
  access_group = 'access_group',
  access_user = 'access_user',
  access_user_import = 'access_user_import',
}

export const ACCOUNT_APPROVED: {
  [id: string]: {
    value: number;
  };
} = Object.freeze({
  not_approved_manually: {
    value: -2,
  },
  not_approved_automatically: {
    value: -1,
  },
  pending: {
    value: 0,
  },
  approved: {
    value: 1,
  },
});

enum ACCOUNT_ROLE_IDS {
  tenant_restricted = 'tenant_restricted',
  tenant_normal = 'tenant_normal',
  owner = 'owner',
  janitor = 'janitor',
  handyman = 'handyman',
  manager = 'manager',
  admin = 'admin',
}

export const ACCOUNT_ROLE_V2: Record<ACCOUNT_ROLE_IDS, IAccountRoleV2> = {
  tenant_restricted: {
    intl_id: 'ACCOUNT_ROLE.TENANT_RESTRICTED',
    value: 'tenant_restricted',
    color: 'gray',
  },
  tenant_normal: {
    intl_id: 'ACCOUNT_ROLE.TENANT',
    value: 'tenant_normal',
    color: 'gray',
  },
  owner: {
    intl_id: 'ACCOUNT_ROLE.OWNER',
    value: 'owner',
    color: 'orange',
  },
  janitor: {
    intl_id: 'ACCOUNT_ROLE.JANITOR',
    value: 'janitor',
    color: 'purple',
  },
  handyman: {
    intl_id: 'ACCOUNT_ROLE.HANDYMAN',
    value: 'handyman',
    color: 'pink',
  },
  manager: {
    intl_id: 'ACCOUNT_ROLE.MANAGER',
    value: 'manager',
    color: 'cyan',
  },
  admin: {
    intl_id: 'ACCOUNT_ROLE.ADMIN',
    value: 'admin',
    color: 'blue',
  },
};

enum ACCESS_RULE_IDS {
  general_tenant = 'general_tenant',
  general_owner_and_up = 'general_owner_and_up',
  general_contractors = 'general_contractors',
  general_staff = 'general_staff',
  general_manager = 'general_manager',
  general_admin = 'general_admin',
  immoapp = 'immoapp',
  invite_activated = 'invite_activated',
  adminapp = 'adminapp',
  documents_user_roles_default = 'documents_user_roles_default',
  documents_inactive_mandates = 'documents_inactive_mandates',
  documents_personal_folders = 'documents_personal_folders',
  documents_edit = 'documents_edit',
  documents_abaimmo = 'documents_abaimmo',
  documents_full_abaimmo = 'documents_full_abaimmo',
  documents_full_default = 'documents_full_default',
  invite_users = 'invite_users',
  voting_immoapp = 'voting_immoapp',
  voting_admin = 'voting_admin',
  tenantadmin = 'tenantadmin',
  internal_ticket_private = 'internal_ticket_private',
  internal_ticket_staff_actions = 'internal_ticket_staff_actions',
  internal_ticket_manager_actions = 'internal_ticket_manager_actions',
  external_ticket = 'external_ticket',
  message_user_roles_default = 'message_user_roles_default',
}

export const ACCESS_RULES: Record<ACCESS_RULE_IDS, TRole[]> = {
  // Who counts as a tenant member
  general_tenant: [
    ACCOUNT_ROLE_V2.tenant_restricted.value,
    ACCOUNT_ROLE_V2.tenant_normal.value,
  ],
  // Who counts as a owner member
  general_owner_and_up: [
    ACCOUNT_ROLE_V2.owner.value,
    ACCOUNT_ROLE_V2.janitor.value,
    ACCOUNT_ROLE_V2.handyman.value,
    ACCOUNT_ROLE_V2.manager.value,
    ACCOUNT_ROLE_V2.admin.value,
  ],
  // Who counts as a contractor
  general_contractors: [
    ACCOUNT_ROLE_V2.janitor.value,
    ACCOUNT_ROLE_V2.handyman.value,
  ],
  // Who counts as a staff member
  general_staff: [
    ACCOUNT_ROLE_V2.janitor.value,
    ACCOUNT_ROLE_V2.handyman.value,
    ACCOUNT_ROLE_V2.manager.value,
    ACCOUNT_ROLE_V2.admin.value,
  ],
  // Who counts as a manager member
  general_manager: [ACCOUNT_ROLE_V2.manager.value, ACCOUNT_ROLE_V2.admin.value],
  // Who counts as a admin member
  general_admin: [ACCOUNT_ROLE_V2.admin.value],
  // Who has access to the immoapp
  immoapp: [
    ACCOUNT_ROLE_V2.tenant_normal.value,
    ACCOUNT_ROLE_V2.owner.value,
    ACCOUNT_ROLE_V2.janitor.value,
    ACCOUNT_ROLE_V2.handyman.value,
    ACCOUNT_ROLE_V2.manager.value,
    ACCOUNT_ROLE_V2.admin.value,
  ],
  // Who has default setting for invite set to true
  invite_activated: [
    ACCOUNT_ROLE_V2.owner.value,
    ACCOUNT_ROLE_V2.janitor.value,
    ACCOUNT_ROLE_V2.handyman.value,
    ACCOUNT_ROLE_V2.manager.value,
    ACCOUNT_ROLE_V2.admin.value,
  ],
  // Who has access to the adminapp
  adminapp: [
    ACCOUNT_ROLE_V2.janitor.value,
    ACCOUNT_ROLE_V2.handyman.value,
    ACCOUNT_ROLE_V2.manager.value,
    ACCOUNT_ROLE_V2.admin.value,
  ],
  // Which user roles have access to documents
  documents_user_roles_default: [
    ACCOUNT_ROLE_V2.tenant_normal.value,
    ACCOUNT_ROLE_V2.owner.value,
    ACCOUNT_ROLE_V2.janitor.value,
    ACCOUNT_ROLE_V2.manager.value,
  ],
  // Who can see inactive mandate folders
  documents_inactive_mandates: [ACCOUNT_ROLE_V2.admin.value],
  // Who can see personal folders
  documents_personal_folders: [
    ACCOUNT_ROLE_V2.manager.value,
    ACCOUNT_ROLE_V2.admin.value,
  ],
  // Who can edit folder access right
  documents_edit: [ACCOUNT_ROLE_V2.manager.value, ACCOUNT_ROLE_V2.admin.value],
  // Who can see documents for the abaimmo system
  documents_abaimmo: [
    ACCOUNT_ROLE_V2.owner.value,
    ACCOUNT_ROLE_V2.janitor.value,
    ACCOUNT_ROLE_V2.handyman.value,
    ACCOUNT_ROLE_V2.manager.value,
    ACCOUNT_ROLE_V2.admin.value,
  ],
  // Who can see ALL documents for the abaimmo system
  documents_full_abaimmo: [
    ACCOUNT_ROLE_V2.manager.value,
    ACCOUNT_ROLE_V2.admin.value,
  ],
  // Who can see ALL documents for the non-abaimmo systems
  documents_full_default: [ACCOUNT_ROLE_V2.admin.value],
  // Who can invite new users
  invite_users: [ACCOUNT_ROLE_V2.admin.value],
  // Who has access to voting
  voting_immoapp: [
    ACCOUNT_ROLE_V2.owner.value,
    ACCOUNT_ROLE_V2.manager.value,
    ACCOUNT_ROLE_V2.admin.value,
  ],
  // Who has access to voting admin functionality
  voting_admin: [ACCOUNT_ROLE_V2.manager.value, ACCOUNT_ROLE_V2.admin.value],
  // Who can see tenant admin
  tenantadmin: [
    ACCOUNT_ROLE_V2.owner.value,
    ACCOUNT_ROLE_V2.manager.value,
    ACCOUNT_ROLE_V2.admin.value,
  ],
  // Who can see private internal tickets
  internal_ticket_private: [
    ACCOUNT_ROLE_V2.janitor.value,
    ACCOUNT_ROLE_V2.handyman.value,
    ACCOUNT_ROLE_V2.manager.value,
    ACCOUNT_ROLE_V2.admin.value,
  ],
  // Who can see staff actions in the report details context menu
  internal_ticket_staff_actions: [
    ACCOUNT_ROLE_V2.janitor.value,
    ACCOUNT_ROLE_V2.handyman.value,
    ACCOUNT_ROLE_V2.manager.value,
    ACCOUNT_ROLE_V2.admin.value,
  ],
  // Who can see manager actions in the report details context menu
  internal_ticket_manager_actions: [
    ACCOUNT_ROLE_V2.manager.value,
    ACCOUNT_ROLE_V2.admin.value,
  ],
  // Who can see external tickets
  external_ticket: [ACCOUNT_ROLE_V2.manager.value, ACCOUNT_ROLE_V2.admin.value],
  // Which user roles are selected by default for a new message
  message_user_roles_default: [
    ACCOUNT_ROLE_V2.tenant_normal.value,
    ACCOUNT_ROLE_V2.owner.value,
    ACCOUNT_ROLE_V2.janitor.value,
    ACCOUNT_ROLE_V2.manager.value,
  ],
};

export enum PERSON_INVITE {
  deleted = -1,
  no_invite = 0,
  invite = 1,
}

export const ACCOUNT_SOURCE: {
  [role: string]: IAccountSource;
} = Object.freeze({
  immoapp_register: {
    intl_id: 'source.immoapp_register',
    value: 'immoapp_register',
  },
  immoapp_tenant: {
    intl_id: 'source.immoapp_tenant',
    value: 'immoapp_tenant',
  },
  immotop_system: {
    intl_id: 'source.immotop_system',
    value: 'immotop_system',
  },
  webapp_admin: {
    intl_id: 'source.webapp_admin',
    value: 'webapp_admin',
  },
  webapp_immomove: {
    intl_id: 'source.webapp_immomove',
    value: 'webapp_immomove',
  },
  webapp_register: {
    intl_id: 'source.webapp_register',
    value: 'webapp_register',
  },
});

export const EMAIL_UNSUBSCRIBE: {
  [role: string]: IEmailUnsubscribe;
} = Object.freeze({
  public: {
    value: 'public',
  },
  settings: {
    value: 'settings',
  },
  none: {
    value: 'none',
  },
});

export enum TAB_TITLE {
  'home' = 'home',
  'documents' = 'documents',
  'voting' = 'voting',
  'profile' = 'profile',
}

export const IM_TENANT_EMAIL_STATUS = Object.freeze({
  send_code: 1,
  resend_code: 2,
  reminder: 3,
});

// Builds on top of IM_PROCESS_STATUS
export const IM_STAFF_EMAIL_STATUS = Object.freeze({
  re_submit: 5,
  voucher_request: 6,
});

export const IM_PROCESS_STATUS = Object.freeze({
  error: {
    intl_id: 'GLOBAL.ERROR',
    value: -1,
  },
  created: {
    intl_id: 'immoMoveHome.created',
    value: 0,
  },
  reminded: {
    intl_id: 'immoMoveHome.reminded',
    value: 1,
  },
  accessed: {
    intl_id: 'immoMoveHome.accessed',
    value: 2,
  },
  answered: {
    intl_id: 'immoMoveHome.answered',
    value: 3,
  },
  submitted: {
    intl_id: 'global.done',
    value: 4,
  },
});

export const REPAIR_STATUS: {
  [role: string]: IRepairStatus;
} = Object.freeze({
  pending: {
    intl_id: 'REPAIR.STATUS_0',
    intl_id_reservation: 'repair.statusZero',
    value: 0,
    color_name: 'grey',
  },
  in_progress: {
    intl_id: 'REPAIR.STATUS_1',
    intl_id_reservation: 'repair.statusOne',
    value: 1,
    color_name: 'orange',
  },
  completed: {
    intl_id: 'REPAIR.STATUS_2',
    intl_id_reservation: 'reservation.confirmed',
    value: 2,
    color_name: 'green',
  },
});

export const IF_PDF_SELECTION = Object.freeze({
  overview_immovable: {
    intl_id: 'pdfexport.overviewImmovable',
    value: 'overview_immovable',
  },
  overview_calculations: {
    intl_id: 'pdfexport.overviewCalculations',
    value: 'overview_calculations',
  },
  overview_components: {
    intl_id: 'pdfexport.overviewComponents',
    value: 'overview_components',
  },
});

export const VOTE_STEPS = {
  planning: {
    value: 'planning',
    intl_id: 'votes.planning',
  },
  online: {
    value: 'online',
    intl_id: 'votes.online',
  },
  live: {
    value: 'live',
    intl_id: 'votes.live',
  },
  end: {
    value: 'end',
    intl_id: 'votes.end',
  },
};

export const COMMENT_STATUS = Object.freeze({
  original: 'original',
  edited: 'edited',
  deleted: 'deleted',
});

export const FOLDER_PATTERN = Object.freeze({
  PERSON_SEARCH_ID_EXTRA: 10000000,
  MANDATE: 'MANDATE',
  PROPERTY: 'PROPERTY',
  OBJECT: 'OBJECT',
  PERSON: 'PERSON',
});

export const DATE_FORMAT = {
  display_day_full: 'dddd',
  display_day_complete: 'dddd, DD.MM.YYYY',
  display_full: 'DD.MM.YYYY - HH:mm',
  display_normal: 'DD.MM.YYYY',
  display_named: 'DD MMM YYYY',
  display_short: 'DD.MM.',
  compare_normal: 'DD-MM-YYYY',
  iso_8601: 'YYYY-MM-DD', // https://en.wikipedia.org/wiki/ISO_8601
  iso_string: 'YYYY-MM-DDTHH:mm:ss',
  time: 'HH:mm',
  year: 'YYYY',
};

export enum FILE_TYPE {
  image,
  video,
  pdf,
  other,
}

export const VOTE_USER_STATUS = Object.freeze({
  inactive: {
    intl_id: 'global.inactive',
    value: 0,
  },
  registered: {
    intl_id: 'global.registered',
    value: 1,
  },
  email_sent: {
    intl_id: 'votes.registeredAndEmailSent',
    value: 2,
  },
  voting: {
    intl_id: 'global.voting',
    value: 3,
  },
  completed: {
    intl_id: 'global.completed',
    value: 4,
  },
});

export const VOTE_RESULT = Object.freeze({
  accepted: {
    intl_id: 'votes.accepted',
    value: 1,
  },
  not_valid: {
    intl_id: 'votes.noValidQuorum',
    value: 0,
  },
  rejected: {
    intl_id: 'votes.rejected',
    value: -1,
  },
});

export const VOTE_CHOICE = Object.freeze({
  yes: {
    intl_id: 'yes',
    value: 1,
  },
  abstain: {
    intl_id: 'abstain',
    value: 0,
  },
  no: {
    intl_id: 'no',
    value: -1,
  },
});

export const IMMOVABLE_TYPES = Object.freeze({
  condominium: {
    text: 'Eigentumswohnung',
    value: 'condominium',
  },
  tower: {
    text: 'Hochhaus',
    value: 'tower',
  },
  house: {
    text: 'Einfamilienhaus',
    value: 'house',
  },
  stweg: {
    text: 'Stockwerkeigentumsgemeinschaft',
    value: 'stweg',
  },
  joint_ownership: {
    text: 'Miteigentum',
    value: 'joint_ownership',
  },
});

export const COMPONENT_STATUS = Object.freeze({
  broken: {
    text: 'Defekt',
    value: 1,
    color: '#d43434',
  },
  badly_damaged: {
    text: 'Stark beschädigt',
    value: 2,
    color: '#d43434',
  },
  noticeable_damaged: {
    text: 'Merkbar beschädigt',
    value: 3,
    color: '#FF8800',
  },
  slightly_damaged: {
    text: 'Leicht beschädigt',
    value: 4,
    color: '#f5d600',
  },
  good_condition: {
    text: 'Guter Zustand',
    value: 5,
    color: '#18bb4a',
  },
  like_new: {
    text: 'Neuwertig',
    value: 6,
    color: '#18bb4a',
  },
});

export const BASE64_PREFIX = {
  jpeg: 'data:image/jpeg;base64,',
  png: 'data:image/png;base64,',
};

export const MAX_IMAGE_SIZE_DEFAULT = 1280;
export const MAX_IMAGE_SIZE_EMAIL = 768;

export const IMAGE_TYPES_WITH_THUMB = ['png', 'jpg', 'jpeg', 'webp'];
export const IMAGE_TYPES_ALL = ['png', 'jpg', 'jpeg', 'webp', 'svg', 'gif'];
export const VIDEO_TYPES = ['avi', 'mov', 'mp4', 'webm'];

export enum VOTE_VOTEUSER_STATUS {
  not_allowed,

  warrant_registered,
  warrant_completed,

  notstarted_registered,
  notstarted_notregistered,

  active_registered,
  active_notregistered,
  active_voting,
  active_completed,

  inactive_missed,
  inactive_completed,
}

export const ACCESS_FILE_NAME = '.access';
export const ACCESS_SEPARATOR = '___';
export const HIDDEN_FILES = [ACCESS_FILE_NAME, 'thumbs.db'];
