import { message } from 'antd';
import { MessageType } from 'antd/lib/message/interface';
import { translate } from 'components/Utility/i18n';
import dayjs from 'dayjs';
import { ID } from 'definitions/constants-fe';
import _ from 'lodash';
import { CReport } from '../../../shared/classes';

export const sortReports = (a: CReport, b: CReport): 0 | 1 | -1 => {
  const aMandate = a.mandate_ids[0] ?? -1;
  const bMandate = b.mandate_ids[0] ?? -1;
  const aProperty = a.properties[0]?.property_id ?? -1;
  const bProperty = b.properties[0]?.property_id ?? -1;
  // Compare mandates
  if (aMandate < bMandate) {
    return -1;
  }
  if (aMandate > bMandate) {
    return 1;
  }
  // Compare properties
  if (aProperty < bProperty) {
    return -1;
  }
  if (aProperty > bProperty) {
    return 1;
  }
  return 0;
};

export const isEmptyDeep = (obj: any): any => {
  if (_.isObject(obj)) {
    if (Object.keys(obj).length === 0) {
      return true;
    }
    return _.every(_.map(obj, (v) => isEmptyDeep(v)));
  } else if (_.isString(obj)) {
    return !obj.length;
  }
  return false;
};

export const appendDatabaseKeyToData = (
  data: any = {},
  useInnerData = false,
) => {
  const dataSource: any = [];
  if (useInnerData) {
    Object.keys(data).map((component) => {
      return dataSource.push({
        ...data[component].data,
        key: data[component].id,
      });
    });
  } else {
    Object.keys(data).map((property) => {
      return dataSource.push({
        ...data[property],
        key: property,
      });
    });
  }
  return dataSource;
};

export const formatToReadableNumber = (value: any) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
};

export const outputNumber = (input: any, decimalPart: any) => {
  if (!input || input === '') {
    if (input !== 0) {
      return '--';
    }
  }
  const number = parseFloat(input).toFixed(decimalPart);
  const parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'");
  return parts.join('.');
};

export const handleTimeStamps = (data: any, utc0 = false) => {
  let d: any;
  if (data) {
    d = new Date(
      (data.seconds ? data.seconds : data._seconds ? data._seconds : data) *
        1000,
    );
  } else {
    d = new Date();
  }
  if (utc0) {
    return new Date(d - d.getTimezoneOffset() * 60000);
  }
  return d;
};

export const getFormattedDate = (item: any, format: any) => {
  if (item == null) {
    return '';
  } else {
    if (item.seconds == null) {
      return dayjs(item).format(format);
    } else {
      return dayjs.unix(item.seconds).format(format);
    }
  }
};

export const showMessage = (
  intlString: string,
  intlValues: any,
  type: string = ID.success,
  key = '',
  maxTime: number = 20,
): MessageType => {
  // @ts-ignore
  return message[type]({
    content: (
      <span
        dangerouslySetInnerHTML={{
          __html: translate(intlString, intlValues ?? {}),
        }}
      />
    ),
    duration: type === ID.error ? 6 : type === ID.loading ? maxTime : 4,
    key: key,
  });
};

export const getFileExt = (fileName: string) => {
  if (fileName.split('.').length > 1) {
    return fileName.split('.').pop();
  }
  return null;
};

export const getSwitcherIconBorderColor = () => {
  const currentUrl = window.location.href;
  const res = [
    ID.immoapp,
    ID.immofonds,
    ID.immomove,
    ID.account,
    ID.statistics,
    ID.team,
    ID.settings,
  ].find((ele) => currentUrl.includes(ele));
  return res; // Is the ID or null
};

export const genRandomPassword = (length: number) => {
  let result = '';
  while (result.length < length) {
    const newString = Math.random()
      .toString(36)
      .replace(/[.il01]/g, '');
    result += newString;
  }
  return result.slice(-length);
};

export const getIntlStringMessage = (id: string) => {
  return `${translate(id)}`;
};

export const openInNewTab = (url: string) => {
  const win = window.open(url, '_blank', 'noopener,noreferrer');
  console.info(win, url);
  if (win) {
    win.focus();
  }
};

export const isInternetExplorer = () => {
  // @ts-ignore
  return /*@cc_on!@*/ false || !!document.documentMode;
};

// --- Redux helper ---

export const mergeListenerData = (entries: any) => {
  // Merge
  let merged = entries[ID.public].concat(entries[ID.private]);
  // Remove duplicates
  merged = merged.filter(
    (ele: any, index: any, self: any) =>
      self.findIndex((t: any) => t.id === ele.id) === index,
  );
  // Sorting needs to happen in frontend because we combine 2 arrays
  merged.sort((a: any, b: any) => {
    if (a.date_creation < b.date_creation) {
      return 1;
    }
    if (a.date_creation > b.date_creation) {
      return -1;
    }
    return 0;
  });
  return merged;
};

export const addFilesTimeCreated = (files: any) => {
  return files.map((ele: any) => {
    ele.time_created = handleTimeStamps(ele.time_created);
    return ele;
  });
};

export const getView = (width: number) => {
  let newView = ID.mobile_view;
  if (width >= 1220) {
    newView = ID.desktop_view;
  } else if (width >= 768) {
    newView = ID.tab_view;
  }
  return newView;
};
