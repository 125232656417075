import { LinkConfig } from 'ckeditor5';
import { translate } from 'components/Utility/i18n';
import { getIntlStringMessage } from 'library/functionHelper';
import { ACCOUNT_ROLE_V2 } from '../../../shared/constants';
import { TLang } from '../../../shared/types';

export const TAB_IDENTIFIER = '?tab=';

export const CK_EDITOR_TOOLBAR = [
  'heading',
  '|',
  'bold',
  'italic',
  'underline',
  'link',
  '|',
  'numberedList',
  'bulletedList',
];

export const CK_EDITOR_LINK_SETTINGS: LinkConfig = {
  decorators: {
    openInNewTab: {
      mode: 'manual',
      label: 'Open in a new tab',
      defaultValue: true,
      attributes: {
        target: '_blank',
        rel: 'noopener noreferrer',
      },
    },
  },
};

export const TICKET_TYPE = Object.freeze({
  repair: {
    value: 'repair',
    intl_id: 'ticket.repair',
  },
  reservation: {
    value: 'reservation',
    intl_id: 'GLOBAL.RESERVATION',
  },
  keyorder: {
    value: 'keyorder',
    intl_id: 'ticket.keyorder',
  },
  nameplate: {
    value: 'nameplate',
    intl_id: 'ticket.nameplate',
  },
  document: {
    value: 'document',
    intl_id: 'ticket.document',
  },
  other: {
    value: 'other',
    intl_id: 'ticket.other',
  },
  voteitem: {
    value: 'voteitem',
    intl_id: 'ticket.voteitem',
  },
});

export const MAX_SURVEY_CHOICES = 9;

export const ID = {
  head: 'head',
  body: 'body',
  de: 'de',
  it: 'it',
  fr: 'fr',
  en: 'en',
  type: 'type',
  uid: 'uid',
  dataentryist_name: 'dataentryist_name',
  ticket_type: 'ticket_type',
  keyorder: 'keyorder',
  nameplate: 'nameplate',
  details: 'details',
  case_number: 'case_number',
  entry_id: 'entry_id',
  tab_name: 'tab_name',
  yes: 'yes',
  yes_flatfox: 'yes_flatfox',
  yes_flatfox_homegate: 'yes_flatfox_homegate',
  no: 'no',
  reset: 'reset',
  infinity: 'infinity',
  undecided: 'undecided',
  flexible: 'flexible',
  title: 'title',
  user_roles: 'user_roles',
  choices: 'choices',
  location_details: 'location_details',
  description: 'description',
  note_protocol: 'note_protocol',
  note_internal: 'note_internal',
  comments_enabled: 'comments_enabled',
  push_status: 'push_status',
  confirmation_needed: 'confirmation_needed',
  email_status: 'email_status',
  email_status_external: 'email_status_external',
  update_date: 'update_date',
  send_push: 'send_push',
  message: 'message',
  repair: 'repair',
  pinboard: 'pinboard',
  form_guide: 'form_guide',
  archive: 'archive',
  archived: 'archived',
  vote: 'vote',
  repair_status: 'repair_status',
  voteitem: 'voteitem',
  handyman: 'handyman',
  timeline_description: 'timeline_description',
  timeline_repair_status: 'timeline_repair_status',
  timeline: 'timeline',
  mandate_id: 'mandate_id',
  mandate_ids: 'mandate_ids',
  mandate_data: 'mandate_data',
  mandate_name: 'mandate_name',
  date: 'date',
  receiver: 'receiver',
  date_creation: 'date_creation',
  is_public: 'is_public',
  date_sign: 'date_sign',
  owner_sign: 'owner_sign',
  status: 'status',
  mail_delivery: 'mail_delivery',
  janitor: 'janitor',
  owner: 'owner',
  owners: 'owners',
  name: 'name',
  owner_count: 'owner_count',
  object_id: 'object_id',
  object_name: 'object_name',
  phone_home: 'phone_home',
  show_as_contact: 'show_as_contact',
  communication_restricted: 'communication_restricted',
  role: 'role',
  active: 'active',
  completed: 'completed',
  send_email: 'send_email',
  phone_number: 'phone_number',
  extra_info: 'extra_info',
  company: 'company',
  email_external: 'email_external',
  component: 'component',
  replace_cost: 'replace_cost',
  remain_service_time: 'remain_service_time',
  state: 'state',
  status_auto: 'status_auto',
  current_deposit: 'current_deposit',
  current_balance: 'current_balance',
  minimum_threshold: 'minimum_threshold',
  gvz_percentage: 'gvz_percentage',
  yearly_correction_threshold: 'yearly_correction_threshold',
  status_description: 'status_description',
  cubature_insureance_value: 'cubature_insureance_value',
  elevator_stops: 'elevator_stops',
  electricity_usage: 'electricity_usage',
  quantity: 'quantity',
  insurance_value: 'insurance_value',
  offer: 'offer',
  available: 'available',
  estimate: 'estimate',
  sqmeter: 'sqmeter',
  condominium: 'condominium',
  tower: 'tower',
  house: 'house',
  stweg: 'stweg',
  joint_ownership: 'joint_ownership',
  comments: 'comments',
  city: 'city',
  street: 'street',
  rating: 'rating',
  updated_at: 'updated_at',
  salutation: 'salutation',
  first_name: 'first_name',
  last_name: 'last_name',
  number: 'number',
  address: 'address',
  process_type: 'process_type',
  items: 'items',
  live: 'live',
  live_status: 'live_status',
  online: 'online',
  manual: 'manual',
  warrant: 'warrant',
  mode: 'mode',
  date_end: 'date_end',
  date_start: 'date_start',
  survey: 'survey',
  vote_consider: 'vote_consider',
  vote_code: 'vote_code',
  vote_status: 'vote_status',
  vote_properties: 'vote_properties',
  vote_updated: 'vote_updated',
  vote_type: 'vote_type',
  vote_results: 'vote_results',
  vote_person: 'vote_person',
  vote_quota: 'vote_quota',
  votes_choice: 'votes_choice',
  eligible_participants: 'eligible_participants',
  quota: 'quota',
  results: 'results',
  apartments: 'apartments',
  base: 'base',
  factor: 'factor',
  calculation_base: 'calculation_base',
  renewal_date: 'renewal_date',
  renovation_date: 'renovation_date',
  construction_date: 'construction_date',
  ground_area: 'ground_area',
  optimal_start_balance: 'optimal_start_balance',
  immovable_type: 'immovable_type',
  immovable_id: 'immovable_id',
  sender_last_name: 'sender.last_name',
  user_id: 'user_id',
  sender_uid: 'sender.uid',
  incl_interest: 'incl_interest',
  incl_comp_images: 'incl_comp_images',
  progressbar_notification: 'progressbar_notification',
  pdf_size_error_notification: 'pdf_size_error_notification',
  name_account: 'name_account',
  balance: 'balance',
  account_number: 'account_number',
  name_bank: 'name_bank',
  rate_interest: 'rate_interest',
  rate_inflation: 'rate_inflation',
  editing_left_basic: 'editing_left_basic',
  editing_right_basic: 'editing_right_basic',
  editing_calculation_right: 'editing_calculation_right',
  uploading: 'uploading',
  synced: 'synced',
  missing: 'missing',
  public: 'public',
  private: 'private',
  merged: 'merged',
  none: 'none',
  yarowa_service_staging: 'yarowa_service_staging',
  yarowa_service_production: 'yarowa_service_production',
  action: 'action',
  yarowa: 'yarowa',
  file_size: 'file_size',
  last_updated_at: 'last_updated_at',
  on_submit: 'on_submit',
  access_group: 'access_group',
  access_user: 'access_user',
  access_user_import: 'access_user_import',
  email_message: 'email_message',
  email_repair: 'email_repair',
  email_repair_update: 'email_repair_update',
  email_pinboard: 'email_pinboard',
  email_comment: 'email_comment',
  email_votes: 'email_votes',
  email_voteitem: 'email_voteitem',
  communication: 'communication',
  content: 'content',
  percentage: 'percentage',
  day: 'day',
  year: 'year',
  property: 'property',
  object: 'object',
  objects: 'objects',
  properties: 'properties',
  consider_for_vote: 'consider_for_vote',
  quorum: 'quorum',
  quorum_predefined: 'quorum_predefined',
  incl_quota: 'incl_quota',
  min_participants: 'min_participants',
  incl_all: 'incl_all',
  incl_abst: 'incl_abst',
  incl_tie: 'incl_tie',
  majority_perc: 'majority_perc',
  vote_count: 'vote_count',
  erp_connection: 'erp_connection',
  warrant_email: 'warrant_email',
  relationship_id: 'relationship_id',
  relationship_ids: 'relationship_ids',
  count_head: 'count_head',
  count_object: 'count_object',
  vote_count_completed: 'vote_count_completed',
  vote_count_voting: 'vote_count_voting',
  time_start: 'time_start',
  time_end: 'time_end',
  interval: 'interval',
  weekdays: 'weekdays',

  // DB
  desc: 'desc',
  asc: 'asc',
  array: 'array',
  array_contains: 'array-contains',
  array_contains_any: 'array-contains-any',
  in: 'in',
  property_id_full: 'property_id_full',
  property_id: 'property_id',
  property_ids: 'property_ids',
  person_id: 'person_id',
  approved: 'approved',
  user_receiver_ids: 'user_receiver_ids',
  user_involved_ids: 'user_involved_ids',

  // CSS
  communicationDisabledBadge: 'communicationDisabledBadge',
  pendingUserBadge: 'pendingUserBadge',

  // ERP
  immotop: 'immotop',
  abaimmo: 'abaimmo',

  // Icons
  printer: 'printer',
  close: 'close',
  check: 'check',

  // Filter
  mandate_screen: 'mandate_screen',
  message_screen: 'message_screen',
  repair_screen: 'repair_screen',
  pinboard_screen: 'pinboard_screen',
  vote_screen: 'vote_screen',
  person_screen: 'person_screen',
  immofonds_home_screen: 'immofonds_home_screen',
  immomove_home_screen: 'immomove_home_screen',
  role_filter: 'role_filter',
  status_filter: 'status_filter',
  date_filter: 'date_filter',
  files_sync_filter: 'files_sync_filter',
  quantity_filter: 'quantity_filter',
  verification_filter: 'verification_filter',
  login_filter: 'login_filter',
  mandate_filter: 'mandate_filter',
  email_verified: 'email_verified',
  search_filter: 'search_filter',
  time_created: 'time_created',
  time_last_login: 'time_last_login',

  // Login
  login: 'login',
  invited: 'invited',
  email: 'email',
  last_name_ci: 'last_name_ci',
  zip_code: 'zip_code',
  password: 'password',
  current_password: 'current_password',
  repeat_password: 'repeat_password',

  // Success message
  info: 'info',
  warning: 'warning',
  success: 'success',
  error: 'error',
  loading: 'loading',
  failure: 'failure',

  // Products titles and routes
  immoapp: 'immoapp',
  immofonds: 'immofonds',
  immomove: 'immomove',
  account: 'account',
  statistics: 'statistics',
  team: 'team',
  settings: 'settings',
  portal: 'portal',
  tenant: 'tenant',

  // Files
  rclone: 'rclone',
  syncme: 'syncme',
  file_upload: 'file_upload',
  files: 'files',

  // Shuffle
  grid: 'grid',
  list: 'list',

  // Action
  insert: 'insert',
  update: 'update',
  update_timeline: 'update_timeline',
  insert_voteitem: 'insert_voteitem',
  do_edit: 'do_edit',
  delete: 'delete',
  reactivate: 'reactivate',
  copy: 'copy',
  view: 'view',
  approve: 'approve',
  cancel: 'cancel',
  reject: 'reject',
  download_pdf: 'download_pdf',
  comment: 'comment',
  forward: 'forward',
  duplicate: 'duplicate',
  resend_access: 'resend_access',
  preview_email: 'preview_email',
  delete_faqSection: 'delete_faqSection',
  delete_faqItem: 'delete_faqItem',
  add_faqItem: 'add_faqItem',
  add_faqSection: 'add_faqSection',

  // Modal
  none_reset: 'none_reset',
  message_edit_view_modal: 'message_edit_view_modal',
  repair_edit_view_modal: 'repair_edit_view_modal',
  pinboard_edit_view_modal: 'pinboard_edit_view_modal',
  edit_view_modal: 'edit_view_modal',
  comment_modal: 'comment_modal',
  mail_delivery_modal: 'mail_delivery_modal',
  timeline_modal: 'timeline_modal',
  yarowa_modal: 'yarowa_modal',
  bank_modal: 'bank_modal',
  component_modal: 'component_modal',
  download_pdf_modal: 'download_pdf_modal',
  staff_modal: 'staff_modal',
  user_modal: 'user_modal',
  participant_modal: 'participant_modal',
  contacts_modal: 'contacts_modal',
  mandate_edit_modal: 'mandate_edit_modal',
  forgot_access_modal: 'forgot_access_modal',
  email_failure_modal: 'email_failure_modal',
  email_forward_modal: 'email_forward_modal',
  account_email_modal: 'account_email_modal',
  account_password_modal: 'account_password_modal',
  register_modal: 'register_modal',
  edit_folder_modal: 'edit_folder_modal',
  insert_traktandum_modal: 'insert_traktandum_modal',
  edit_traktandum_modal: 'edit_traktandum_modal',
  vote_results_modal: 'vote_results_modal',
  translation_modal: 'translation_modal',
  reservation_modal: 'reservation_modal',

  // Notification
  if_pdf_notification: 'if_pdf_notification',
  ia_license_notification: 'ia_license_notification',

  // file types
  rootPerson: 'rootPerson',
  rootMandate: 'rootMandate',
  folder: 'folder',
  image: 'image',
  text: 'text',
  application: 'application',
  pdf: 'pdf',
  zip: 'zip',
  gif: 'gif',
  compressed: 'compressed',
  wordprocessingml_document: 'wordprocessingml_document',
  spreadsheetml_sheet: 'spreadsheetml_sheet',
  presentationml_presentation: 'presentationml_presentation',
  vnd_ms_powerpoint: 'vnd_ms_powerpoint',

  // Screen View
  mobile_view: 'mobile_view',
  desktop_view: 'desktop_view',
  tab_view: 'tab_view',
  inline: 'inline',
  vertical: 'vertical',

  // Random
  character_limit_reached: 'character_limit_reached',
  state_changed: 'state_changed',
  key: 'key',
  checked: 'checked',
};

export const PREVIEW_MAIL_STATUS = {
  idle: 'idle',
  sending: 'sending',
  success: 'success',
  failure: 'failure',
  select_mandates: 'select_mandates',
};

export const Quorum = {
  simple_majority_noabst: {
    value: 'simple_majority_noabst',
    intl_id: 'votes.simpleMajorityNoAbstention',
    splitted: {
      majority_perc: 50,
      incl_all: false,
      incl_abst: false,
      incl_quota: false,
      incl_tie: false,
      min_participants: 0,
    },
  },
  simple_majority_withabst: {
    value: 'simple_majority_withabst',
    intl_id: 'votes.simpleMajorityWithAbstention',
    splitted: {
      majority_perc: 50,
      incl_all: false,
      incl_abst: true,
      incl_quota: false,
      incl_tie: false,
      min_participants: 0,
    },
  },
  qualified_majority_noabst: {
    value: 'qualified_majority_noabst',
    intl_id: 'votes.qualifiedMajorityNoAbstention',
    splitted: {
      majority_perc: 50,
      incl_all: false,
      incl_abst: false,
      incl_quota: true,
      incl_tie: false,
      min_participants: 0,
    },
  },
  qualified_majority_withabst: {
    value: 'qualified_majority_withabst',
    intl_id: 'votes.qualifiedMajorityWithAbstention',
    splitted: {
      majority_perc: 50,
      incl_all: false,
      incl_abst: true,
      incl_quota: true,
      incl_tie: false,
      min_participants: 0,
    },
  },
  // simple_total_majority: {
  //   value: 'simple_total_majority',
  //   intl_id: 'votes.simpleTotalMajority',
  //   color: '#2f54eb', // Dark blue
  // },
  // qualified_total_majority: {
  //   value: 'qualified_total_majority',
  //   intl_id: 'votes.qualifiedTotalMajority',
  //   color: '#2f54eb', // Dark blue
  // },
  // unanimity_attendees: {
  //   value: 'unanimity_attendees',
  //   intl_id: 'votes.unanimityAttendees',
  //   color: '#442feb', // Purple
  // },
  // unanimity_total: {
  //   value: 'unanimity_total',
  //   intl_id: 'votes.unanimityTotal',
  //   color: '#442feb', // Purple
  // },
};

export const QuorumSliderMarks = {
  0: '0%',
  25: '25%',
  33.33: '1/3',
  50: '50%',
  66.66: '2/3',
  75: '75%',
  100: '100%',
};

export const VoteCount = {
  head: {
    value: 'head',
    intl_id: 'votes.headCount',
  },
  object: {
    value: 'object',
    intl_id: 'votes.objectCount',
  },
};

export const Validation = {
  max_text_length: 5000,
};

export const STRING_TOOLS = {
  access_file: '.access',
  input_separator: '___',
};

export const ProductStrings = {
  immoapp: 'ImmoApp',
  immofonds: 'ImmoFonds',
  immomove: 'ImmoMove',
};

export const ScreenLayout = {
  min_heights: {
    with_topbar: 205,
    without_topbar: 117,
    mobile: 53,
  },
};

export const MessageScreen = {
  tabs: {
    mandates: { value: 'mandates', intl_id: 'GLOBAL.MANDATES' },
    properties: { value: 'properties', intl_id: 'global.properties' },
    users: { value: 'users', intl_id: 'global.user' },
  },
  all_mandates: 0,
};

export const DateFormat = {
  display_full: 'DD.MM.YYYY - HH:mm',
  display_normal: 'DD.MM.YYYY',
  display_named: 'DD MMM YYYY',
  display_short: 'DD.MM.',
  compare_normal: 'DD-MM-YYYY',
  time: 'HH:mm',
  year: 'YYYY',
};

export const DateFormatDynamic = () => ({
  display_complete: `DD.MM.YYYY [${translate(
    'global.timeInfix',
  )}] HH:mm [${translate('global.timeSuffix')}]`,
});

export const MandateScreen = {
  missing: 0,
  synced: 1,
  files_status: {
    missing: {
      intl_id: 'global.missing',
      value: 'missing',
    },
    synced: {
      intl_id: 'global.synced',
      value: 'synced',
    },
  },
  active_inactive_status: {
    inactive: {
      intl_id: 'global.inactive',
      value: 'inactive',
    },
    active: {
      intl_id: 'global.active',
      value: 'active',
    },
  },
};

export const StatsScreen = {
  immoapp_stats_tab: 'immoapp',
  immomove_stats_tab: 'immomove',
};

export const rowStyle = {
  width: '100%',
  display: 'flex',
  flexFlow: 'row wrap',
};

export const SettingsScreen = {
  settings_tabs: {
    license: 'license',
    accounts: 'accounts',
    ci: 'ci',
    communication: 'communication',
    masterdata: 'masterdata',
    infocenter: 'infocenter',
    immomove: 'immomove',
  },
};

export const PaginationSize = ['20', '50', '100'];

export const HiddenFiles = ['.access', 'thumbs.db'];

export const VoteScreen = {
  maxTitleLength: 100,
  vote_status: {
    scheduled: {
      intl_id: 'global.scheduled',
      value: 0,
    },
    active: {
      intl_id: 'global.active',
      value: 1,
    },
    completed: {
      intl_id: 'global.completed',
      value: 2,
    },
  },
};

export const HomeScreen = {
  tabs: {
    mandates: { value: 1, intl_id: 'GLOBAL.MANDATES' },
    property: { value: 2, intl_id: 'global.properties' },
  },
  ground_area_filter: {
    compare: 'groundarea_compare',
    value: 'groundarea_value',
  },
  insurance_value_filter: {
    compare: 'insurancevalue_compare',
    value: 'insurancevalue_value',
  },
  construction_year_filter: {
    compare: 'constructionyear_compare',
    value: 'constructionyear_value',
  },
};

export const ImmoFondsConstants = {
  formula: { adding_value: 30, multiplication_value: 17 },
  calculation_bases: {
    elevator_stops: {
      intl_id: 'component.elevator_stops',
      value: ID.elevator_stops,
    },
    electricity_usage: {
      intl_id: 'component.electricity_usage',
      value: ID.electricity_usage,
    },
    quantity: { intl_id: 'component.quantity', value: ID.quantity },
    insurance_value: {
      intl_id: 'component.insurance_value',
      value: ID.insurance_value,
    },
    offer: { intl_id: 'component.offer', value: ID.offer },
    estimate: { intl_id: 'component.estimate', value: ID.estimate },
    sqmeter: { intl_id: 'component.sqmeter', value: ID.sqmeter },
  },
};

export const ImmoMoveConstants = {
  Questionnaire: {
    checkbox_input_separator: '___',
    textInputLimit: 100,
    textAreaLimit: 1000,
  },
  HomeScreen: {
    staff: 'staff_member',
    city_filter: {
      compare: 'city_compare',
      value: 'city_value',
    },
    rating_filter: {
      compare: 'rating_compare',
      value: 'rating_value',
    },
    created_at_filter: {
      compare: 'time_created_compare',
      value: 'time_created_value',
    },
    termination_filter: {
      compare: 'termination_compare',
    },
    process_types: {
      regular: {
        intl_id: 'terminationType.regular',
        value: 'regular',
      },
      irregular: {
        intl_id: 'terminationType.irregular',
        value: 'irregular',
      },
    },
    salutations: {
      male: {
        intl_id: 'salutation.male',
        value: 'male',
      },
      female: {
        intl_id: 'salutation.female',
        value: 'female',
      },
    },
  },
};

export const CountryCodes = {
  '+41': '+41',
  '+49': '+49',
  without: getIntlStringMessage('global.withoutAreaCode'),
};

export const ImageTypes = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];

export const FileExtensions = {
  previewAllowed: ['png', 'jpg', 'jpeg', 'gif', 'svg', 'pdf'],
  images: ['jpg', 'jpeg', 'png', 'gif'],
  videos: ['mp4', 'wmv', 'avi'],
};

export const FileUploadProps: {
  addUid: boolean;
  imageFromDifferentFolder: boolean;
  useFileName: string | null;
  isCopy: boolean;
} = {
  addUid: false,
  imageFromDifferentFolder: false,
  useFileName: null,
  isCopy: false,
};

export const SALES_EMAIL = 'servicedesk@aandarta.ch';
export const SALES_PHONE = '+41 43 205 13 13';

export const FETCH_TIME_LIMIT: number = 12;

export const LANGUAGES: Record<TLang, string> = Object.freeze({
  de: 'Deutsch',
  en: 'English',
  fr: 'Français',
  it: 'Italiano',
});

export const ERROR_STRINGS = Object.freeze({
  internal: 'INTERNAL',
  auth_weak_password: 'auth/weak-password',
  auth_email_exists: 'auth/email-already-in-use',
  inline_timeline_description: 'inline_timeline_description',
});

export const VOTETYPE = {
  vote_online_only: {
    value: 'vote_online_only',
    intl_id: 'votes.onlineOnly',
  },
  vote_online_live: {
    value: 'vote_online_live',
    intl_id: 'votes.onlineAndLive',
  },
  vote_live_only: {
    value: 'vote_live_only',
    intl_id: 'votes.liveOnly',
  },
};

export const SURVEYTYPE = {
  survey_online: {
    value: 'survey_online',
    intl_id: 'survey.online',
  },
};

export const ENTRY_CATEGORY = Object.freeze({
  message: {
    intl_id: 'global.message',
    value: 'message',
  },
  repair: {
    intl_id: 'global.repair',
    value: 'repair',
  },
  repair_update: {
    intl_id: 'global.updateRepair',
    value: 'repair_update',
  },
  pinboard: {
    intl_id: 'global.pinboard',
    value: 'pinboard',
  },
  comment: {
    intl_id: 'GLOBAL.COMMENT',
    value: 'comment',
  },
  votes: {
    intl_id: 'global.vote',
    value: 'votes',
  },
  voteitem: {
    intl_id: 'global.agendaItem',
    value: 'voteitem',
  },
});

export const USER_VOTE_CONSIDERATION = Object.freeze({
  no: {
    intl_id: 'votes.consideredNo',
    value: 0,
  },
  pending: {
    intl_id: 'votes.consideredPending',
    value: 1,
  },
  yes: {
    intl_id: 'votes.consideredYes',
    value: 2,
  },
});

export const USER_SOURCE = Object.freeze({
  immoapp_register: {
    intl_id: 'source.immoapp_register',
    value: 'immoapp_register',
  },
  immoapp_tenant: {
    intl_id: 'source.immoapp_tenant',
    value: 'immoapp_tenant',
  },
  immotop_system: {
    intl_id: 'source.immotop_system',
    value: 'immotop_system',
  },
  webapp_admin: {
    intl_id: 'source.webapp_admin',
    value: 'webapp_admin',
  },
  webapp_immomove: {
    intl_id: 'source.webapp_immomove',
    value: 'webapp_immomove',
  },
  webapp_register: {
    intl_id: 'source.webapp_register',
    value: 'webapp_register',
  },
});

export const IMMOVABLE_TYPES = Object.freeze({
  condominium: {
    intl_id: 'immovable.condominium',
    value: 'condominium',
  },
  tower: {
    intl_id: 'immovable.tower',
    value: 'tower',
  },
  house: {
    intl_id: 'immovable.house',
    value: 'house',
  },
  stweg: {
    intl_id: 'immovable.stweg',
    value: 'stweg',
  },
  joint_ownership: {
    intl_id: 'immovable.joint_ownership',
    value: 'joint_ownership',
  },
});

export const COMPONENT_STATUS = Object.freeze({
  broken: {
    intl_id: 'component.broken',
    value: 1,
    color: '#d43434',
  },
  badly_damaged: {
    intl_id: 'component.badlyDamaged',
    value: 2,
    color: '#d43434',
  },
  noticeable_damaged: {
    intl_id: 'component.noticeableDamaged',
    value: 3,
    color: '#FF8800',
  },
  slightly_damaged: {
    intl_id: 'component.slightlyDamaged',
    value: 4,
    color: '#f5d600',
  },
  good_condition: {
    intl_id: 'component.goodCondition',
    value: 5,
    color: '#18bb4a',
  },
  like_new: {
    intl_id: 'component.likeNew',
    value: 6,
    color: '#18bb4a',
  },
});

export const ALL_ENTRIES_ID = 0;
