import * as Sentry from '@sentry/react';
import { TSentry } from '../../../../shared/types';

export const Reporting: TSentry = {
  Error(errorObject: string | Error | unknown, extras?: string[]): void {
    Sentry.withScope((scope) => {
      if (extras != null) {
        for (let i = 0; i < extras.length; i++) {
          scope.setExtra(`extra${i}`, extras[i]);
        }
      }
      Sentry.captureException(errorObject);
    });
    console.error('SENTRY ERROR:', errorObject, extras ?? '');
  },
  Warning(message: string, extras?: string[]): void {
    Sentry.withScope((scope) => {
      if (extras != null) {
        for (let i = 0; i < extras.length; i++) {
          scope.setExtra(`extra${i}`, extras[i]);
        }
      }
      Sentry.captureMessage(message, 'warning');
    });
    console.warn('SENTRY WARN:', message, extras ?? '');
  },
  Info(message: string, extras?: string[]): void {
    Sentry.withScope((scope) => {
      if (extras != null) {
        for (let i = 0; i < extras.length; i++) {
          scope.setExtra(`extra${i}`, extras[i]);
        }
      }
      Sentry.captureMessage(message, 'info');
    });
    console.info('SENTRY INFO:', message, extras ?? '');
  },
};
