import { ID } from 'definitions/constants-fe';
import { getIntlStringMessage } from 'library/functionHelper';

export const IFWizard = {
  // as not to rely on indexes for steps on error tracking and other, we have created keys and values for steps
  steps: {
    data: 'data',
    components: 'components',
    ef: 'ef',
  },
  components: {
    facade: {
      id: 'facade',
      name: getIntlStringMessage('ifwizard.component.type.facade'),
      base: ID.sqmeter,
      subtypes: {
        plastered: {
          lifespan: 35,
          factor: 10000,
        },
        painted: {
          lifespan: 25,
          factor: 10000,
        },
        wood: {
          lifespan: 30,
          factor: 10000,
        },
      },
    },
    building_isolation: {
      id: 'building_isolation',
      name: getIntlStringMessage('ifwizard.component.type.building_isolation'),
      base: ID.quantity,
      subtypes: {
        styrofoam: {
          lifespan: 25,
          factor: 3000,
        },
        mineral_wool_insulation: {
          lifespan: 30,
          factor: 3000,
        },
      },
    },
    roof: {
      id: 'roof',
      name: getIntlStringMessage('ifwizard.component.type.roof'),
      base: ID.sqmeter,
      subtypes: {
        pitched_with_bricks: {
          lifespan: 45,
          factor: 3000,
        },
        flat_with_grit: {
          lifespan: 30,
          factor: 3000,
        },
      },
    },
    electrical_installations: {
      id: 'electrical_installations',
      name: getIntlStringMessage(
        'ifwizard.component.type.electrical_installations',
      ),
      base: ID.quantity,
      subtypes: {
        low_quality: {
          lifespan: 30,
          factor: 800,
        },
        mid_quality: {
          lifespan: 35,
          factor: 900,
        },
        high_quality: {
          lifespan: 40,
          factor: 1000,
        },
      },
    },
    sanitary_lines: {
      id: 'sanitary_lines',
      name: getIntlStringMessage('ifwizard.component.type.sanitary_lines'),
      base: ID.quantity,
      subtypes: {
        low_quality: {
          lifespan: 35,
          factor: 2000,
        },
        mid_quality: {
          lifespan: 40,
          factor: 2500,
        },
        high_quality: {
          lifespan: 45,
          factor: 3000,
        },
      },
    },
    heater: {
      id: 'heater',
      name: getIntlStringMessage('ifwizard.component.type.heater'),
      base: ID.quantity,
      subtypes: {
        burner: {
          lifespan: 15,
          factor: 2000,
        },
        heat_pumps: {
          lifespan: 15,
          factor: 2000,
        },
        steel_boiler: {
          lifespan: 20,
          factor: 2500,
        },
        underfloor_heating: {
          lifespan: 30,
          factor: 4000,
        },
        radiators: {
          lifespan: 40,
          factor: 3000,
        },
      },
    },
    windows: {
      id: 'windows',
      name: getIntlStringMessage('ifwizard.component.type.windows'),
      base: ID.quantity,
      subtypes: {
        wood: {
          lifespan: 30,
          factor: 3000,
        },
        plastic: {
          lifespan: 25,
          factor: 2500,
        },
      },
    },
    elevator: {
      id: 'elevator',
      name: getIntlStringMessage('ifwizard.component.type.elevator'),
      base: ID.elevator_stops,
      subtypes: {
        low_quality: {
          lifespan: 20,
          factor: 4000,
        },
        mid_quality: {
          lifespan: 25,
          factor: 4500,
        },
        high_quality: {
          lifespan: 30,
          factor: 5000,
        },
      },
    },
    stockroom: {
      id: 'stockroom',
      name: getIntlStringMessage('ifwizard.component.type.stockroom'),
      base: ID.quantity,
      subtypes: {
        small: {
          lifespan: 40,
          factor: 500,
        },
        medium: {
          lifespan: 40,
          factor: 600,
        },
        big: {
          lifespan: 40,
          factor: 700,
        },
      },
    },
    common_room: {
      id: 'common_room',
      name: getIntlStringMessage('ifwizard.component.type.common_room'),
      base: ID.quantity,
      subtypes: {
        small: {
          lifespan: 40,
          factor: 500,
        },
        medium: {
          lifespan: 40,
          factor: 600,
        },
        big: {
          lifespan: 40,
          factor: 700,
        },
      },
    },
    shelter: {
      id: 'shelter',
      name: getIntlStringMessage('ifwizard.component.type.shelter'),
      base: ID.quantity,
      subtypes: {
        small: {
          lifespan: 50,
          factor: 500,
        },
        medium: {
          lifespan: 50,
          factor: 600,
        },
        big: {
          lifespan: 50,
          factor: 700,
        },
      },
    },
    visitor_parking: {
      id: 'visitor_parking',
      name: getIntlStringMessage('ifwizard.component.type.visitor_parking'),
      base: ID.quantity,
      subtypes: {
        small: {
          lifespan: 50,
          factor: 500,
        },
        medium: {
          lifespan: 50,
          factor: 600,
        },
        big: {
          lifespan: 50,
          factor: 700,
        },
      },
    },
    driveway: {
      id: 'driveway',
      name: getIntlStringMessage('ifwizard.component.type.driveway'),
      base: ID.quantity,
      subtypes: {
        small: {
          lifespan: 50,
          factor: 200,
        },
        medium: {
          lifespan: 50,
          factor: 300,
        },
        big: {
          lifespan: 50,
          factor: 400,
        },
      },
    },
    bicycle_space: {
      id: 'bicycle_space',
      name: getIntlStringMessage('ifwizard.component.type.bicycle_space'),
      base: ID.quantity,
      subtypes: {
        small: {
          lifespan: 50,
          factor: 200,
        },
        medium: {
          lifespan: 50,
          factor: 300,
        },
        big: {
          lifespan: 50,
          factor: 400,
        },
      },
    },
    paintwork: {
      id: 'paintwork',
      name: getIntlStringMessage('ifwizard.component.type.paintwork'),
      base: ID.quantity,
      subtypes: {
        minor: {
          lifespan: 30,
          factor: 200,
        },
        extensive: {
          lifespan: 30,
          factor: 800,
        },
      },
    },
    hot_water_boiler: {
      id: 'hot_water_boiler',
      name: getIntlStringMessage('ifwizard.component.type.hot_water_boiler'),
      base: ID.quantity,
      subtypes: {
        low_quality: {
          lifespan: 15,
          factor: 600,
        },
        mid_quality: {
          lifespan: 20,
          factor: 700,
        },
        high_quality: {
          lifespan: 25,
          factor: 800,
        },
      },
    },
    washing_machine_dryer: {
      id: 'washing_machine_dryer',
      name: getIntlStringMessage(
        'ifwizard.component.type.washing_machine_dryer',
      ),
      base: ID.quantity,
      subtypes: {
        small: {
          lifespan: 40,
          factor: 100,
        },
        medium: {
          lifespan: 40,
          factor: 150,
        },
        big: {
          lifespan: 40,
          factor: 200,
        },
      },
    },
    playground: {
      id: 'playground',
      name: getIntlStringMessage('ifwizard.component.type.playground'),
      base: ID.quantity,
      subtypes: {
        small: {
          lifespan: 40,
          factor: 100,
        },
        medium: {
          lifespan: 40,
          factor: 150,
        },
        big: {
          lifespan: 40,
          factor: 200,
        },
      },
    },
    garden: {
      id: 'garden',
      name: getIntlStringMessage('ifwizard.component.type.garden'),
      base: ID.quantity,
      subtypes: {
        small: {
          lifespan: 40,
          factor: 100,
        },
        medium: {
          lifespan: 40,
          factor: 150,
        },
        big: {
          lifespan: 40,
          factor: 200,
        },
      },
    },
    winter_garden: {
      id: 'winter_garden',
      name: getIntlStringMessage('ifwizard.component.type.winter_garden'),
      base: ID.quantity,
      subtypes: {
        small: {
          lifespan: 40,
          factor: 100,
        },
        medium: {
          lifespan: 40,
          factor: 150,
        },
        big: {
          lifespan: 40,
          factor: 200,
        },
      },
    },
    balcony: {
      id: 'balcony',
      name: getIntlStringMessage('ifwizard.component.type.balcony'),
      base: ID.quantity,
      subtypes: {
        small: {
          lifespan: 40,
          factor: 100,
        },
        medium: {
          lifespan: 40,
          factor: 150,
        },
        big: {
          lifespan: 40,
          factor: 200,
        },
      },
    },
    flooring: {
      id: 'flooring',
      name: getIntlStringMessage('ifwizard.component.type.flooring'),
      base: ID.quantity,
      subtypes: {
        laminate: {
          lifespan: 15,
          factor: 400,
        },
        pvc: {
          lifespan: 20,
          factor: 500,
        },
        tiling: {
          lifespan: 30,
          factor: 600,
        },
        parquet: {
          lifespan: 40,
          factor: 700,
        },
      },
    },
    doors: {
      id: 'doors',
      name: getIntlStringMessage('ifwizard.component.type.doors'),
      base: ID.quantity,
      subtypes: {
        low_quality: {
          lifespan: 20,
          factor: 100,
        },
        mid_quality: {
          lifespan: 25,
          factor: 150,
        },
        high_quality: {
          lifespan: 30,
          factor: 200,
        },
      },
    },
    fence_and_wall: {
      id: 'fence_and_wall',
      name: getIntlStringMessage('ifwizard.component.type.fence_and_wall'),
      base: ID.quantity,
      subtypes: {
        small: {
          lifespan: 40,
          factor: 100,
        },
        medium: {
          lifespan: 40,
          factor: 150,
        },
        big: {
          lifespan: 40,
          factor: 200,
        },
      },
    },
    ventilation_ducts: {
      id: 'ventilation_ducts',
      name: getIntlStringMessage('ifwizard.component.type.ventilation_ducts'),
      base: ID.quantity,
      subtypes: {
        small: {
          lifespan: 40,
          factor: 100,
        },
        medium: {
          lifespan: 40,
          factor: 150,
        },
        big: {
          lifespan: 40,
          factor: 200,
        },
      },
    },
    slat_blinds: {
      id: 'slat_blinds',
      name: getIntlStringMessage('ifwizard.component.type.slat_blinds'),
      base: ID.quantity,
      subtypes: {
        low_quality: {
          lifespan: 20,
          factor: 100,
        },
        mid_quality: {
          lifespan: 25,
          factor: 150,
        },
        high_quality: {
          lifespan: 30,
          factor: 200,
        },
      },
    },
    terrace_floor: {
      id: 'terrace_floor',
      name: getIntlStringMessage('ifwizard.component.type.terrace_floor'),
      base: ID.quantity,
      subtypes: {
        small: {
          lifespan: 40,
          factor: 100,
        },
        medium: {
          lifespan: 40,
          factor: 150,
        },
        big: {
          lifespan: 40,
          factor: 200,
        },
      },
    },
    // not based on apartments
    solar_collector: {
      id: 'solar_collector',
      name: getIntlStringMessage('ifwizard.component.type.solar_collector'),
      base: ID.quantity,
      subtypes: {
        small: {
          lifespan: 20,
          factor: 20000,
        },
        medium: {
          lifespan: 20,
          factor: 35000,
        },
        big: {
          lifespan: 20,
          factor: 50000,
        },
      },
    },
    // not based on apartments
    water_softening_system: {
      id: 'water_softening_system',
      name: getIntlStringMessage(
        'ifwizard.component.type.water_softening_system',
      ),
      base: ID.quantity,
      subtypes: {
        low_quality: {
          lifespan: 15,
          factor: 10000,
        },
        mid_quality: {
          lifespan: 20,
          factor: 15000,
        },
        high_quality: {
          lifespan: 25,
          factor: 20000,
        },
      },
    },
    // not based on apartments
    fireplace: {
      id: 'fireplace',
      name: getIntlStringMessage('ifwizard.component.type.fireplace'),
      base: ID.quantity,
      subtypes: {
        low_quality: {
          lifespan: 30,
          factor: 10000,
        },
        mid_quality: {
          lifespan: 35,
          factor: 15000,
        },
        high_quality: {
          lifespan: 40,
          factor: 20000,
        },
      },
    },
  },
  subtypes: {
    small: {
      id: 'small',
      name: getIntlStringMessage('ifwizard.component.subtype.small'),
    },
    medium: {
      id: 'medium',
      name: getIntlStringMessage('ifwizard.component.subtype.medium'),
    },
    big: {
      id: 'big',
      name: getIntlStringMessage('ifwizard.component.subtype.big'),
    },
    minor: {
      id: 'minor',
      name: getIntlStringMessage('ifwizard.component.subtype.minor'),
    },
    extensive: {
      id: 'extensive',
      name: getIntlStringMessage('ifwizard.component.subtype.extensive'),
    },
    wood: {
      id: 'wood',
      name: getIntlStringMessage('ifwizard.component.subtype.wood'),
    },
    plastic: {
      id: 'plastic',
      name: getIntlStringMessage('ifwizard.component.subtype.plastic'),
    },
    low_quality: {
      id: 'low_quality',
      name: getIntlStringMessage('ifwizard.component.subtype.low_quality'),
    },
    mid_quality: {
      id: 'mid_quality',
      name: getIntlStringMessage('ifwizard.component.subtype.mid_quality'),
    },
    high_quality: {
      id: 'high_quality',
      name: getIntlStringMessage('ifwizard.component.subtype.high_quality'),
    },
    metal: {
      id: 'metal',
      name: getIntlStringMessage('ifwizard.component.subtype.metal'),
    },
    aluminium: {
      id: 'aluminium',
      name: getIntlStringMessage('ifwizard.component.subtype.aluminium'),
    },
    laminate: {
      id: 'laminate',
      name: getIntlStringMessage('ifwizard.component.subtype.laminate'),
    },
    pvc: {
      id: 'pvc',
      name: getIntlStringMessage('ifwizard.component.subtype.pvc'),
    },
    tiling: {
      id: 'tiling',
      name: getIntlStringMessage('ifwizard.component.subtype.tiling'),
    },
    parquet: {
      id: 'parquet',
      name: getIntlStringMessage('ifwizard.component.subtype.parquet'),
    },
    burner: {
      id: 'burner',
      name: getIntlStringMessage('ifwizard.component.subtype.burner'),
    },
    heat_pumps: {
      id: 'heat_pumps',
      name: getIntlStringMessage('ifwizard.component.subtype.heat_pumps'),
    },
    steel_boiler: {
      id: 'steel_boiler',
      name: getIntlStringMessage('ifwizard.component.subtype.steel_boiler'),
    },
    underfloor_heating: {
      id: 'underfloor_heating',
      name: getIntlStringMessage(
        'ifwizard.component.subtype.underfloor_heating',
      ),
    },
    radiators: {
      id: 'radiators',
      name: getIntlStringMessage('ifwizard.component.subtype.radiators'),
    },
    flat_with_grit: {
      id: 'flat_with_grit',
      name: getIntlStringMessage('ifwizard.component.subtype.flat_with_grit'),
    },
    pitched_with_bricks: {
      id: 'pitched_with_bricks',
      name: getIntlStringMessage(
        'ifwizard.component.subtype.pitched_with_bricks',
      ),
    },
    plastered: {
      id: 'plastered',
      name: getIntlStringMessage('ifwizard.component.subtype.plastered'),
    },
    painted: {
      id: 'painted',
      name: getIntlStringMessage('ifwizard.component.subtype.painted'),
    },
    styrofoam: {
      id: 'styrofoam',
      name: getIntlStringMessage('ifwizard.component.subtype.styrofoam'),
    },
    mineral_wool_insulation: {
      id: 'mineral_wool_insulation',
      name: getIntlStringMessage(
        'ifwizard.component.subtype.mineral_wool_insulation',
      ),
    },
  },
};

export const DefaultBankAccountValues = {
  rate_inflation: 0.5,
  rate_interest: 0.1,
  name_account: '-',
  name_bank: '-',
  account_number: '-',
};

export const DefaultImmovableValues = {
  gvz_percentage: 0.25,
  cubature_insureance_value: 27000,
  minimum_threshold: 0,
  deposit_received: false,
  cloud_cache: '',
  yearly_correction_threshold: 0,
  image: {},
};

export const DefaultComponentValues = {
  active: true,
  base: 1,
  calculated_data: '',
  calculation_base: ID.estimate,
  images: [],
  status_auto: true,
  status_description: '-',
};
