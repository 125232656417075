import { ID } from 'definitions/constants-fe';

export const QuestionnaireScreen = {
  cancel_reason: {
    key: 'cancel_reason',
    a_private_reasons: 'a_private_reasons',
    b_object_reasons: 'b_object_reasons',
    c_location_reasons: 'c_location_reasons',
    d_different_reasons: 'd_different_reasons',
    check: {
      a_private_reasons: {
        professional_change: 'professional_change',
        need_smaller_apartment: 'need_smaller_apartment',
        need_larger_apartment: 'need_larger_apartment',
        homestead: 'homestead',
        separation: 'separation',
        moving_in_together: 'moving_in_together',
        children_born: 'children_born',
        death: 'death',
        other: 'other',
      },
      b_object_reasons: {
        facilities_dated: 'facilities_dated',
        existing_flaw: 'existing_flaw',
        inconvenient_layout: 'inconvenient_layout',
        not_age_appropriate: 'not_age_appropriate',
        facilities_missing: 'facilities_missing',
        high_house_rent: 'high_house_rent',
        poor_appearance: 'poor_appearance',
        noise_immission: 'noise_immission',
        other: 'other',
      },
      c_location_reasons: {
        not_child_friendly: 'not_child_friendly',
        no_parking: 'no_parking',
        no_visitor_parking: 'no_visitor_parking',
        distance_main_roads: 'distance_main_roads',
        bad_connection_public_transport: 'bad_connection_public_transport',
        distance_groceries: 'distance_groceries',
        other: 'other',
      },
      d_different_reasons: {
        discrepancies_within_neighborhood: 'discrepancies_within_neighborhood',
        discrepancies_with_janitor: 'discrepancies_with_janitor',
        discrepancies_with_admin: 'discrepancies_with_admin',
        other: 'other',
      },
    },
  },
  apartment_liked: {
    key: 'apartment_liked',
    a_object: 'a_object',
    b_location: 'b_location',
    check: {
      a_object: {
        good_facilities: 'good_facilities',
        convenient_layout: 'convenient_layout',
        age_appropriate: 'age_appropriate',
        fair_house_rent: 'fair_house_rent',
        other: 'other',
      },
      b_location: {
        child_friendly: 'child_friendly',
        pleasant_neighborhood: 'pleasant_neighborhood',
        reliable_janitor: 'reliable_janitor',
        competent_administration: 'competent_administration',
        quiet_area: 'quiet_area',
        proximity_main_roads: 'proximity_main_roads',
        good_connection_public_transport: 'good_connection_public_transport',
        proximity_groceries: 'proximity_groceries',
      },
    },
  },
  advertisement: {
    check: {
      yes_flatfox: ID.yes_flatfox,
      yes_flatfox_homegate: ID.yes_flatfox_homegate,
      no: ID.no,
    },
    key: 'advertisement',
  },
  phone_number: {
    check: { yes: ID.yes, no: ID.no },
    key: 'phone_number',
    data: 'data',
    country_code: 'country_code',
    number: 'number',
  },
  visitor_dates: {
    check: { yes: ID.yes, flexible: ID.flexible },
    key: 'visitor_dates',
    date: 'date',
    time: 'time',
  },
  admin_inspection_dates: {
    check: { yes: ID.yes, flexible: ID.flexible },
    key: 'admin_inspection_dates',
    date: 'date',
    time: 'time',
  },
  new_address: {
    check: { yes: ID.yes, no: ID.no },
    key: 'new_address',
    data: 'data',
    street: 'street',
    number: 'number',
    city: 'city',
    zip_code: 'zip_code',
  },
  bank_account: {
    check: { yes: ID.yes, no: ID.no },
    key: 'bank_account',
    data: 'data',
    bank_name: 'bank_name',
    bank_location: 'bank_location',
    account_owner: 'account_owner',
    iban: 'iban',
  },
  apartment_registered_people: {
    key: 'apartment_registered_people',
    last_name: 'last_name',
    first_name: 'first_name',
    date_of_birth: 'date_of_birth',
  },
  civil_status: {
    check: {
      single: 'single',
      married: 'married',
      judicially_separated: 'judicially_separated',
      divorced: 'divorced',
      widowed: 'widowed',
    },
    key: 'civil_status',
  },
  tv_isp: {
    check: {
      none: 'none',
      swisscom: 'swisscom',
      upc_cablecom: 'upc_cablecom',
      sunrise: 'sunrise',
      salt: 'salt',
      glattnet: 'glattnet',
      gga_maur: 'gga_maur',
      lkw: 'lkw',
      ew_rumlang: 'ew_rumlang',
      other: 'other',
    },
    key: 'tv_isp',
  },
  apartment_handover: {
    check: { yes: ID.yes, no: ID.no, flexible: ID.flexible },
    date: 'date',
    time: 'time',
    key: 'apartment_handover',
  },
  known_defects: { check: { yes: ID.yes, no: ID.no }, key: 'known_defects' },
  admin_ratings: {
    key: 'admin_ratings',
    accessibility: 'accessibility',
    kindness: 'kindness',
    reaction_time: 'reaction_time',
    competence: 'competence',
  },
  improvement_suggestions: {
    check: { yes: ID.yes, no: ID.no },
    key: 'improvement_suggestions',
  },
  admin_reuse: {
    check: { yes: ID.yes, no: ID.no, undecided: ID.undecided },
    key: 'admin_reuse',
  },
  personal_feedback: {
    check: { yes: ID.yes, no: ID.no },
    key: 'personal_feedback',
  },
  reference_approval: {
    check: { yes: ID.yes, no: ID.no },
    key: 'reference_approval',
  },
};
