export const predictableIdAndTodayCode = (id: string) => {
  // First part of code: Use the first 2 numbers of the voteId or empty array in case there are no numbers at all
  let firstPart: string =
    id.match(/\d/g)?.join('').replace(/^0+/, '').substring(0, 2) ?? '';
  while (firstPart.length < 2) {
    firstPart = 1 + firstPart;
  }
  // Second part of code: Use a code that represents today
  const oneDayInMs = 1000 * 60 * 60 * 24;
  const currentTimeInMs = new Date().getTime(); // UTC time
  const timeInDays = Math.floor(currentTimeInMs / oneDayInMs);
  const numberForToday = timeInDays % 99;
  let secondPart = numberForToday.toString();
  while (secondPart.length < 2) {
    secondPart = 1 + secondPart;
  }
  return parseInt(firstPart + secondPart);
};
