import { oldUserRoleToNew } from '../../../shared/snippets';

export function clearUserRole() {
  try {
    sessionStorage.removeItem('user_role');
    localStorage.removeItem('user_role');
  } catch (err) {}
}

export function getUserRole(raw: boolean) {
  try {
    const userRole = (() => {
      if (isSession()) {
        return sessionStorage.getItem('user_role');
      } else {
        return localStorage.getItem('user_role');
      }
    })();
    if (userRole == null) {
      return null;
    }
    return raw ? userRole : oldUserRoleToNew(userRole);
  } catch (err) {
    return null;
  }
}

export function setUserRole(val: any) {
  try {
    if (isSession()) {
      sessionStorage.setItem('user_role', val);
    } else {
      localStorage.setItem('user_role', val);
    }
  } catch (err) {}
}

export function clearToken() {
  try {
    sessionStorage.removeItem('id_token');
    localStorage.removeItem('id_token');
  } catch (err) {}
}

export function getToken() {
  try {
    if (isSession()) {
      return sessionStorage.getItem('id_token');
    } else {
      return localStorage.getItem('id_token');
    }
  } catch (err) {
    clearToken();
    return null;
  }
}

export function setToken(val: any) {
  try {
    if (isSession()) {
      sessionStorage.setItem('id_token', val);
    } else {
      localStorage.setItem('id_token', val);
    }
  } catch (err) {}
}

// Not relevant if session or not
export function shouldShowLicenseNotice() {
  try {
    const val: any = localStorage.getItem('license_notice');
    if (val == null) {
      return true;
    }
    if (new Date().getTime() > val) {
      localStorage.removeItem('license_notice');
      return true;
    }
    return false;
  } catch (err) {
    return true;
  }
}

export function setLicenseNotice() {
  try {
    // Hide for 3 days
    localStorage.setItem(
      'license_notice',
      (new Date().getTime() + 1000 * 3600 * 24 * 3).toString(),
    );
  } catch (err) {}
}

const isSession = () => {
  return Object.keys(sessionStorage).some((key) =>
    key.toLowerCase().includes('firebase:authuser'),
  );
};
