import ProjectStyles from 'assets/styles/globalStyle';
import React from 'react';
import { Provider } from 'react-redux';
import Boot from 'redux/boot';
import { store } from 'redux/store';
import AppProvider from './AppProvider';
import PublicRoutes from './router';

const App = () => (
  <Provider store={store}>
    <AppProvider>
      <ProjectStyles />
      <PublicRoutes />
    </AppProvider>
  </Provider>
);
Boot()
  .then(() => App())
  .catch((error) => console.error(error));

export default App;
