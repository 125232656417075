import { ID, PREVIEW_MAIL_STATUS } from 'definitions/constants-fe';
import { mergeListenerData, showMessage } from 'library/functionHelper';
import { Reporting } from 'library/sentry/reporting';
import { RootState_ImmoApp } from 'redux/root-saga';
import { REMOTE_CONFIG } from '../../../../shared/constants';
import actions from './actions';

const initState: RootState_ImmoApp = {
  // Since we might have multiple operations at the same time, we need a state for all
  mLoading: [],
  mMsgRef: null,
  rx_loading: false,
  rx_error: null,

  rx_commonSettings: null,
  rx_users: [],
  rx_mandates: [],
  rx_rootFolders: null,
  rx_properties: [],
  rx_messages: {
    public: [],
    private: [],
    merged: [],
  },
  rx_repairs: {
    public: [],
    private: [],
    merged: [],
  },
  rx_pinboards: {
    public: [],
    private: [],
    merged: [],
  },
  rx_folderContent: {
    data: [],
    path: null,
    breadcrumb: [],
  },
  rx_fetchTimeLimit: {
    message: true,
    repair: true,
    pinboard: true,
    vote: true,
  },
  rx_votes: [],
  rx_remoteConfigUpdated: 0,
  rx_voteItems: [],
  rx_voteUsers: [],
  rx_downloadVoteResultsData: null,
  rx_downloadVoteProtocolData: null,
  rx_sendExternalPreviewMailStatus: PREVIEW_MAIL_STATUS.idle,
  rx_sendHandymanPreviewMailStatus: PREVIEW_MAIL_STATUS.idle,
  rx_immoMoveFiles: [],
  rx_infocenterFiles: [],
  rx_infocenterFAQSections: [],
  rx_emailDeliveryResults: [],
  rx_personData: null,
  rx_warrantReceivers: null,
  rx_mandateProperties: [],
  rx_allMandateData: {
    properties: [],
    persons: [],
  },
  rx_settingsData: null,
  rx_filemanagerSettings: null,
  rx_reportReceivers: null,
  rx_createUsersNotification: {
    type: null,
    persons: [],
  },
  // true = success, string = errorMessage, null = default
  rx_forwardEmailResult: null,
  // true = success, string = errorMessage, null = default
  rx_excelMasterDataResult: null,
  rx_remoteConfigAccessComments: true,
  rx_remoteConfigAccessPinboard: true,
  rx_remoteConfigAccessDocuments: true,
  rx_remoteConfigAccessVoteItemSubmit: true,
  rx_statisticsData: [],
  rx_licenseObjects: null,
  rx_immoMoveStatisticsData: [],
  rx_logoUpdateCounter: 1,
  rx_newAccountPassword: null,
};

export default function reducer(
  state = initState,
  { type, payload }: { type: string; payload: any },
) {
  const typeName = type.split('/')[0];
  let temp = null;
  switch (type) {
    // GET COMMON SETTINGS REALTIME
    case actions.subscribeCommonSettings.TRIGGER:
      return {
        ...state,
      };
    case actions.subscribeCommonSettings.REQUEST:
      return {
        ...state,
      };
    case actions.subscribeCommonSettings.SUCCESS:
      return {
        ...state,
        rx_commonSettings: payload,
      };
    case actions.subscribeCommonSettings.FAILURE:
      return {
        ...state,
      };
    case actions.subscribeCommonSettings.FULFILL:
      return {
        ...state,
      };

    // GET USERS REALTIME
    case actions.subscribeUsers.TRIGGER:
      return {
        ...state,
      };
    case actions.subscribeUsers.REQUEST:
      if (state.rx_users.length === 0) {
        state.mLoading.push(typeName);
      }
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeUsers.SUCCESS:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        // If singleUser gets returned, only set new data, if the array is empty.
        ...((!payload.singleUser || state.rx_users.length <= 1) && {
          rx_users: payload.res,
        }),
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeUsers.FAILURE:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_error: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeUsers.FULFILL:
      return {
        ...state,
        rx_error: null,
      };

    // GET MANDATES REALTIME
    case actions.subscribeMandates.TRIGGER:
      return {
        ...state,
      };
    case actions.subscribeMandates.REQUEST:
      if (state.rx_mandates.length === 0) {
        state.mLoading.push(typeName);
      }
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeMandates.SUCCESS:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_mandates: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeMandates.FAILURE:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_error: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeMandates.FULFILL:
      return {
        ...state,
        rx_error: null,
      };
    // GET PROPERTIES REALTIME
    case actions.subscribeProperties.TRIGGER:
      return {
        ...state,
      };
    case actions.subscribeProperties.REQUEST:
      if (state.rx_properties.length === 0) {
        state.mLoading.push(typeName);
      }
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeProperties.SUCCESS:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_properties: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeProperties.FAILURE:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_error: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeProperties.FULFILL:
      return {
        ...state,
        rx_error: null,
      };

    // GET MESSAGES REALTIME
    case actions.subscribeMessages.TRIGGER:
      return {
        ...state,
      };
    case actions.subscribeMessages.REQUEST:
      if (state.rx_messages.merged.length === 0) {
        state.mLoading.push(typeName);
      }
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeMessages.SUCCESS:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      temp = { ...state.rx_messages, [payload.type]: payload.data };
      temp.merged = mergeListenerData(temp);
      return {
        ...state,
        rx_messages: temp,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeMessages.FAILURE:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_error: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeMessages.FULFILL:
      return {
        ...state,
        rx_error: null,
      };

    // GET REPAIRS REALTIME
    case actions.subscribeRepairs.TRIGGER:
      return {
        ...state,
      };
    case actions.subscribeRepairs.REQUEST:
      if (state.rx_repairs.merged.length === 0) {
        state.mLoading.push(typeName);
      }
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeRepairs.SUCCESS:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      temp = { ...state.rx_repairs, [payload.type]: payload.data };
      temp.merged = mergeListenerData(temp);
      return {
        ...state,
        rx_repairs: temp,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeRepairs.FAILURE:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_error: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeRepairs.FULFILL:
      return {
        ...state,
        rx_error: null,
      };

    // GET PINBOARDS REALTIME
    case actions.subscribePinboards.TRIGGER:
      return {
        ...state,
      };
    case actions.subscribePinboards.REQUEST:
      if (state.rx_pinboards.merged.length === 0) {
        state.mLoading.push(typeName);
      }
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribePinboards.SUCCESS:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      temp = { ...state.rx_pinboards, [payload.type]: payload.data };
      temp.merged = mergeListenerData(temp);
      return {
        ...state,
        rx_pinboards: temp,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribePinboards.FAILURE:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_error: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribePinboards.FULFILL:
      return {
        ...state,
        rx_error: null,
      };

    // GET VOTES REALTIME
    case actions.subscribeVotes.REQUEST:
      if (state.rx_votes.length === 0) {
        state.mLoading.push(typeName);
      }
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeVotes.SUCCESS:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_votes: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeVotes.FAILURE:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_error: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeVotes.FULFILL:
      return {
        ...state,
        rx_error: null,
      };

    // GET VOTE ITEMS REALTIME
    case actions.subscribeVoteItems.REQUEST:
      if (state.rx_voteItems.length === 0) {
        state.mLoading.push(typeName);
      }
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeVoteItems.SUCCESS:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_voteItems: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeVoteItems.FAILURE:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_error: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeVoteItems.FULFILL:
      return {
        ...state,
        rx_error: null,
      };

    // SUBSCRIBE VOTE USERS REALTIME
    case actions.subscribeVoteUsers.REQUEST:
      if (state.rx_voteUsers.length === 0) {
        state.mLoading.push(typeName);
      }
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeVoteUsers.SUCCESS:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_voteUsers: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeVoteUsers.FAILURE:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_error: payload,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.subscribeVoteUsers.FULFILL:
      return {
        ...state,
        rx_error: null,
      };

    // SET FOLDER META ACCESS
    case actions.setFolderMetaAccess.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.setFolderMetaAccess.SUCCESS:
      return {
        ...state,
      };
    case actions.setFolderMetaAccess.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.setFolderMetaAccess.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // SET FETCH TIME LIMIT
    case actions.setFetchTimeLimit.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.setFetchTimeLimit.SUCCESS:
      state.mLoading.push(typeName);
      const tmp = state.rx_fetchTimeLimit;
      // @ts-ignore
      tmp[payload] = false;
      return {
        ...state,
        rx_fetchTimeLimit: tmp,
      };
    case actions.setFetchTimeLimit.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // GET FOLDER CONTENT
    case actions.getFolderContent.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.getFolderContent.SUCCESS:
      payload.breadcrumb = state.rx_folderContent.breadcrumb.concat(
        payload.breadcrumb,
      );
      payload.breadcrumb.splice(
        payload.breadcrumb.findIndex((b: any) => b.path === payload.path) + 1,
      );
      return {
        ...state,
        rx_folderContent: payload,
      };
    case actions.getFolderContent.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.getFolderContent.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // CLEAR FOLDER CONTENT
    case actions.clearFolderContent.TRIGGER:
      return {
        ...state,
        rx_folderContent: {
          ...initState.rx_folderContent,
        },
      };

    // GET ALL MANDATE PROPERTIES DATA
    case actions.getAllMandateData.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.getAllMandateData.SUCCESS:
      return {
        ...state,
        rx_allMandateData: payload,
      };
    case actions.getAllMandateData.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.getAllMandateData.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // GET MANDATE PROPERTIES
    case actions.getMandateProperties.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.getMandateProperties.SUCCESS:
      return {
        ...state,
        rx_mandateProperties: payload,
      };
    case actions.getMandateProperties.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.getMandateProperties.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // GET ROOT FOLDERS
    case actions.getRootFolders.TRIGGER:
      return {
        ...state,
      };
    case actions.getRootFolders.SUCCESS:
      return {
        ...state,
        rx_rootFolders: payload,
      };
    case actions.getRootFolders.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.getRootFolders.FULFILL:
      return {
        ...state,
        rx_error: null,
      };

    // GET PERSON
    case actions.getPerson.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.getPerson.SUCCESS:
      return {
        ...state,
        rx_personData: payload,
      };
    case actions.getPerson.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.getPerson.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_personData: null,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // GET EMAIL DELIVERY DETAILS
    case actions.getEmailDeliveryDetails.SUCCESS:
      return {
        ...state,
        rx_emailDeliveryResults: payload,
      };

    // SEARCH MANDATE USERS BY LASTNAME OR EMAIL
    case actions.searchMandateUsersByLastNameOrEmail.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.searchMandateUsersByLastNameOrEmail.SUCCESS:
      return {
        ...state,
        rx_warrantReceivers: payload,
      };
    case actions.searchMandateUsersByLastNameOrEmail.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.searchMandateUsersByLastNameOrEmail.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
        rx_warrantReceivers: null,
      };

    // RESERVATIONS ACTION
    case actions.reservationAction.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.reservationAction.SUCCESS:
      showMessage(payload, null, ID.success);
      return {
        ...state,
      };
    case actions.reservationAction.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.reservationAction.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // MANDATE ACTION
    case actions.mandateAction.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.mandateAction.SUCCESS:
      showMessage(payload, null, ID.success);
      return {
        ...state,
      };
    case actions.mandateAction.FAILURE:
      showMessage(payload, null, ID.error);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.mandateAction.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // MESSAGE ACTION
    case actions.messageAction.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.messageAction.SUCCESS:
      showMessage(payload, null, ID.success);
      return {
        ...state,
      };
    case actions.messageAction.FAILURE:
      showMessage(payload, null, ID.error);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.messageAction.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // REPAIR ACTION
    case actions.repairAction.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.repairAction.SUCCESS:
      showMessage(payload, null, ID.success);
      return {
        ...state,
      };
    case actions.repairAction.FAILURE:
      showMessage(payload, null, ID.error);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.repairAction.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // PINBOARD ACTION
    case actions.pinboardAction.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.pinboardAction.SUCCESS:
      showMessage(payload, null, ID.success);
      return {
        ...state,
      };
    case actions.pinboardAction.FAILURE:
      showMessage(payload, null, ID.error);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.pinboardAction.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // USER ACTION
    case actions.userAction.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };

    case actions.userAction.SUCCESS:
      return {
        ...state,
        rx_newAccountPassword: payload,
      };
    case actions.userAction.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.userAction.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
        rx_newAccountPassword: null,
      };

    // VOTE ACTION
    case actions.voteAction.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.voteAction.SUCCESS:
      showMessage(payload, null, ID.success);
      return {
        ...state,
      };
    case actions.voteAction.FAILURE:
      showMessage(payload, null, ID.error);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.voteAction.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // VOTE ACTION
    case actions.insertVoteItem.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.insertVoteItem.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.insertVoteItem.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // TOGGLE VOTE STATE
    case actions.updateVote.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.updateVote.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };

    // IMPORT VOTE USERS
    case actions.importVoteUsers.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.importVoteUsers.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };

    // VOTE USER ACTION
    case actions.voteUserAction.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.voteUserAction.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.voteUserAction.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // DOWNLOAD VOTE RESULTS
    case actions.downloadVoteResults.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.downloadVoteResults.SUCCESS:
      return {
        ...state,
        rx_downloadVoteResultsData: payload,
      };
    case actions.downloadVoteResults.FAILURE:
      return {
        ...state,
        rx_downloadVoteResultsData: payload,
      };
    case actions.downloadVoteResults.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_downloadVoteResultsData: null,
        rx_loading: state.mLoading.length > 0,
      };

    // DOWNLOAD VOTE PROTOCOL
    case actions.downloadVoteProtocol.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.downloadVoteProtocol.SUCCESS:
      return {
        ...state,
        rx_downloadVoteProtocolData: payload,
      };
    case actions.downloadVoteProtocol.FAILURE:
      return {
        ...state,
        rx_downloadVoteProtocolData: payload,
      };
    case actions.downloadVoteProtocol.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_downloadVoteProtocolData: null,
        rx_loading: state.mLoading.length > 0,
      };

    // UPDATE VOTE PARTICIPANT QUOTA
    case actions.updateVoteParticipantQuota.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.updateVoteParticipantQuota.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.updateVoteParticipantQuota.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // SYNC DOCUMENTS
    case actions.syncDocuments.TRIGGER:
      state.mLoading.push(typeName);
      showMessage(payload, null, ID.loading, ID.key);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.syncDocuments.SUCCESS:
      showMessage(payload, null, ID.success, ID.key);
      return {
        ...state,
      };
    case actions.syncDocuments.FAILURE:
      showMessage(payload, null, ID.error, ID.key);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.syncDocuments.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // GET SETTINGS
    case actions.getSettings.TRIGGER:
      return {
        ...state,
      };
    case actions.getSettings.SUCCESS:
      return {
        ...state,
        rx_settingsData: payload,
      };
    case actions.getSettings.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.getSettings.FULFILL:
      return {
        ...state,
        rx_error: null,
      };

    // SET FILEMANAGER SETTINGS
    case actions.setFilemanagerSettings.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.setFilemanagerSettings.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };

    // GET FILEMANAGER SETTINGS
    case actions.getFilemanagerSettings.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.getFilemanagerSettings.SUCCESS:
      return {
        ...state,
        rx_filemanagerSettings: payload,
      };
    case actions.getFilemanagerSettings.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.getFilemanagerSettings.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_error: null,
        rx_loading: state.mLoading.length > 0,
      };

    // SEND TEST MAIL
    case actions.sendTestMail.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.sendTestMail.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_error: null,
        rx_loading: state.mLoading.length > 0,
      };

    // EXAMPLE MANDATE
    case actions.exampleMandate.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.exampleMandate.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_error: null,
        rx_loading: state.mLoading.length > 0,
      };

    // SEND EXTERNAL PREVIEW MAIL
    case actions.sendExternalPreviewMail.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_sendExternalPreviewMailStatus: PREVIEW_MAIL_STATUS.sending,
      };
    case actions.sendExternalPreviewMail.SUCCESS:
      return {
        ...state,
        rx_sendExternalPreviewMailStatus: PREVIEW_MAIL_STATUS.success,
      };
    case actions.sendExternalPreviewMail.FAILURE:
      return {
        ...state,
        rx_sendExternalPreviewMailStatus:
          payload ?? PREVIEW_MAIL_STATUS.failure,
      };
    case actions.sendExternalPreviewMail.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };

    // SEND HANDYMAN PREVIEW MAIL
    case actions.sendHandymanPreviewMail.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_sendHandymanPreviewMailStatus: PREVIEW_MAIL_STATUS.sending,
      };
    case actions.sendHandymanPreviewMail.SUCCESS:
      return {
        ...state,
        rx_sendHandymanPreviewMailStatus: PREVIEW_MAIL_STATUS.success,
      };
    case actions.sendHandymanPreviewMail.FAILURE:
      return {
        ...state,
        rx_sendHandymanPreviewMailStatus:
          payload ?? PREVIEW_MAIL_STATUS.failure,
      };
    case actions.sendHandymanPreviewMail.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };

    // RESET EXTERNAL PREVIEW MAIL
    case actions.resetExternalPreviewMail.TRIGGER:
      return {
        ...state,
        rx_sendExternalPreviewMailStatus: PREVIEW_MAIL_STATUS.idle,
      };

    // RESET HANDYMAN PREVIEW MAIL
    case actions.resetHandymanPreviewMail.TRIGGER:
      return {
        ...state,
        rx_sendHandymanPreviewMailStatus: PREVIEW_MAIL_STATUS.idle,
      };

    // GET REPORT RECEIVERS
    case actions.getReportReceivers.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.getReportReceivers.SUCCESS:
      return {
        ...state,
        rx_reportReceivers: payload,
      };
    case actions.getReportReceivers.FAILURE:
      return {
        ...state,
      };
    case actions.getReportReceivers.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_reportReceivers: null,
      };

    // UPDATE REMOTE CONFIG
    case actions.updateRemoteConfigValue.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.updateRemoteConfigValue.SUCCESS:
      return {
        ...state,
        rx_remoteConfigUpdated: state.rx_remoteConfigUpdated + 1,
      };

    case actions.updateRemoteConfigValue.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // RESET ALL DATA
    case actions.resetAllData.TRIGGER:
      state.mLoading.push(typeName);
      showMessage(payload, null, ID.loading, ID.key);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.resetAllData.SUCCESS:
      showMessage(payload, null, ID.success, ID.key);
      return {
        ...state,
      };
    case actions.resetAllData.FAILURE:
      showMessage(payload, null, ID.error, ID.key);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.resetAllData.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // CREATE USERS FROM PERSONS
    case actions.createUsersFromPersons.TRIGGER:
      state.mLoading.push(typeName);
      state.mMsgRef = showMessage(
        payload.securityQuestionApproved === true
          ? 'settings.createUsersInProgress'
          : 'global.actionInProgress',
        null,
        ID.loading,
        ID.key,
        540,
      );
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.createUsersFromPersons.REQUEST:
      if (state.mMsgRef != null) {
        state.mMsgRef();
      }
      return {
        ...state,
        rx_createUsersNotification: {
          type: ID.info,
          persons: payload,
        },
      };
    case actions.createUsersFromPersons.SUCCESS:
      showMessage(payload, null, ID.success, ID.key);
      return {
        ...state,
      };
    case actions.createUsersFromPersons.FAILURE:
      showMessage(payload, null, ID.error, ID.key);
      return {
        ...state,
        rx_error: payload,
        rx_createUsersNotification: {
          type: payload,
          persons: [],
        },
      };
    case actions.createUsersFromPersons.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      state.mMsgRef = null;
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
        rx_createUsersNotification: {
          type: null,
          persons: [],
        },
      };

    // FORWARD EMAIL
    case actions.forwardEmail.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_forwardEmailResult: null,
      };
    case actions.forwardEmail.SUCCESS:
      return {
        ...state,
        rx_forwardEmailResult: true,
      };
    case actions.forwardEmail.FAILURE:
      return {
        ...state,
        rx_forwardEmailResult: payload,
      };
    case actions.forwardEmail.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_forwardEmailResult: null,
      };

    // EXCEL MASTERDATA IMPORT
    case actions.excelMasterDataImport.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_excelMasterDataResult: null,
      };
    case actions.excelMasterDataImport.SUCCESS:
      return {
        ...state,
        rx_excelMasterDataResult: true,
      };
    case actions.excelMasterDataImport.FAILURE:
      return {
        ...state,
        rx_excelMasterDataResult: payload,
      };
    case actions.excelMasterDataImport.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_excelMasterDataResult: null,
      };

    // GET IMMOMOVE FILES
    case actions.getImmoMoveFiles.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.getImmoMoveFiles.SUCCESS:
      return {
        ...state,
        rx_immoMoveFiles: payload,
      };
    case actions.getImmoMoveFiles.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.getImmoMoveFiles.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // SAVE IMMOMOVE FILES
    case actions.saveImmoMoveFiles.TRIGGER:
      state.mLoading.push(typeName);
      showMessage('upload.inprogress', null, ID.loading, ID.key);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.saveImmoMoveFiles.SUCCESS:
      showMessage(payload, null, ID.success, ID.key);
      return {
        ...state,
      };
    case actions.saveImmoMoveFiles.FAILURE:
      showMessage(payload, null, ID.error, ID.key);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.saveImmoMoveFiles.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // GET INFOCENTER FILES
    case actions.getInfocenterFiles.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.getInfocenterFiles.SUCCESS:
      return {
        ...state,
        rx_infocenterFiles: payload,
      };
    case actions.getInfocenterFiles.FAILURE:
      return {
        ...state,
        rx_error: payload,
      };
    case actions.getInfocenterFiles.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // SAVE INFOCENTER FILES
    case actions.saveInfocenterFiles.TRIGGER:
      state.mLoading.push(typeName);
      showMessage('upload.inprogress', null, ID.loading, ID.key);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.saveInfocenterFiles.SUCCESS:
      showMessage(payload, null, ID.success, ID.key);
      return {
        ...state,
      };
    case actions.saveInfocenterFiles.FAILURE:
      showMessage(payload, null, ID.error, ID.key);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.saveInfocenterFiles.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // GET INFOCENTER FAQ SECTIONS REALTIME
    case actions.subscribeFaqs.TRIGGER:
      return {
        ...state,
      };
    case actions.subscribeFaqs.REQUEST:
      return {
        ...state,
      };
    case actions.subscribeFaqs.SUCCESS:
      return {
        ...state,
        rx_infocenterFAQSections: payload,
      };
    case actions.subscribeFaqs.FAILURE:
      return {
        ...state,
      };
    case actions.subscribeFaqs.FULFILL:
      return {
        ...state,
      };

    // SAVE INFOCENTER FAQ SECTIONS
    case actions.saveInfocenterFAQSections.TRIGGER:
      state.mLoading.push(typeName);
      showMessage('GLOBAL.UPDATING', null, ID.loading, ID.key);
      return {
        ...state,
      };
    case actions.saveInfocenterFAQSections.SUCCESS:
      showMessage(payload, null, ID.success, ID.key);
      return {
        ...state,
      };
    case actions.saveInfocenterFAQSections.FAILURE:
      showMessage(payload, null, ID.error, ID.key);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.saveInfocenterFAQSections.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_error: null,
      };

    // UPDATE SETTINGS FIELD
    case actions.updateSettingsField.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.updateSettingsField.SUCCESS:
      showMessage(payload, null, ID.success);
      return {
        ...state,
      };
    case actions.updateSettingsField.FAILURE:
      showMessage(payload, null, ID.error);
      return {
        ...state,
        rx_error: payload,
      };
    case actions.updateSettingsField.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // GET STATISTICS DATA
    case actions.getStatistics.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.getStatistics.SUCCESS:
      return { ...state, rx_statisticsData: payload };
    case actions.getStatistics.FAILURE:
      showMessage(payload, null, ID.error);
      return { ...state, rx_error: payload };
    case actions.getStatistics.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // REMOTE CONFIG ACCESS
    case actions.getRemoteConfigAccess.SUCCESS:
      switch (payload.id) {
        case REMOTE_CONFIG.access_comments:
          return { ...state, rx_remoteConfigAccessComments: payload.result };
        case REMOTE_CONFIG.access_pinboard:
          return { ...state, rx_remoteConfigAccessPinboard: payload.result };
        case REMOTE_CONFIG.access_documents:
          return { ...state, rx_remoteConfigAccessDocuments: payload.result };
        case REMOTE_CONFIG.access_voteitem_submit:
          return {
            ...state,
            rx_remoteConfigAccessVoteItemSubmit: payload.result,
          };
      }
      Reporting.Error(
        new Error(
          `getRemoteConfigAccess has unexpected state: "${payload.id}".`,
        ),
      );
      return { ...state };

    // GET LICENSE OBJECTS
    case actions.getLicenseObjects.TRIGGER:
      return {
        ...state,
      };
    case actions.getLicenseObjects.SUCCESS:
      return { ...state, rx_licenseObjects: payload };
    case actions.getLicenseObjects.FAILURE:
      showMessage(payload, null, ID.error);
      return { ...state, rx_error: payload };
    case actions.getLicenseObjects.FULFILL:
      return {
        ...state,
        rx_error: null,
      };

    // GET IMMOMOVE STATISTICS DATA
    case actions.getImmoMoveStatistics.TRIGGER:
      state.mLoading.push(typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
      };
    case actions.getImmoMoveStatistics.SUCCESS:
      return { ...state, rx_immoMoveStatisticsData: payload };
    case actions.getImmoMoveStatistics.FAILURE:
      showMessage(payload, null, ID.error);
      return { ...state, rx_error: payload };
    case actions.getImmoMoveStatistics.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return {
        ...state,
        rx_loading: state.mLoading.length > 0,
        rx_error: null,
      };

    // LOGO COUNTER
    case actions.logoUpdatedCounter.TRIGGER:
      return {
        ...state,
        rx_logoUpdateCounter: state.rx_logoUpdateCounter + 1,
      };
    default:
      return state;
  }
}
