import Loader from 'components/Utility/loader';
import App from 'containers/global/App/App';
import ErrorScreen from 'containers/global/Page/500';
import { getUserRole } from 'library/localStorageHelper';
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { RootState } from 'redux/root-saga';
import { PUBLIC_ROUTE, TOOL } from 'route.constants';
import { ACCESS_RULES, ACCOUNT_ROLE_V2 } from '../../shared/constants';
import RouterLocationChange from './RouterLocationChange';

const PrivateRoute = ({ component: Component, ...rest }: any) => (
  <Route {...rest} render={(props) => <Component {...props} />} />
);

const LoginPage = React.lazy(() => import('containers/global/Page/signin'));

export default function PublicRoutes() {
  // Strangely, using userAccount here, or the general GL_Auth object, leads to a crash in the login logic...
  // IMPORTANT: Do not change the next line. Do not use { } deconstruction as this will crash the ImmoMove login. Not sure why yet.... [Jan 2025]
  const rx_isLoggedIn = useSelector(
    (state: RootState) => state.GL_Auth.rx_isLoggedIn,
  );
  const userRole = getUserRole(false);
  const isEmployee =
    userRole != null ? ACCESS_RULES.general_staff.includes(userRole) : false;
  const isTenantRestricted =
    userRole != null
      ? userRole === ACCOUNT_ROLE_V2.tenant_restricted.value
      : false;

  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <ErrorScreen>
          <RouterLocationChange />
          <Switch>
            <Route
              exact={true}
              path={PUBLIC_ROUTE.LANDING}
              render={() =>
                ((process.env.REACT_APP_CLIENT_IMMOMOVE_URL != null &&
                  window.location.href.includes(
                    process.env.REACT_APP_CLIENT_IMMOMOVE_URL,
                  )) ||
                  isTenantRestricted) &&
                process.env.REACT_APP_CLIENT_IMMOMOVE_REDIRECT ===
                  String(true) ? (
                  <Redirect to={`/${TOOL.IMMOMOVE}`} />
                ) : (
                  <LoginPage />
                )
              }
            />
            <Route
              exact={true}
              path={`/${TOOL.IMMOMOVE}`}
              component={() => <LoginPage />}
            />
            <Route
              exact={true}
              path={PUBLIC_ROUTE.PDF_INTRODUCTION}
              component={React.lazy(
                () => import('containers/global/Pdf/Introduction'),
              )}
            />
            <Route
              exact={true}
              path={PUBLIC_ROUTE.PAGE_403}
              component={React.lazy(() => import('containers/global/Page/403'))}
            />
            {/* User has to be logged in AND (immomove user OR employee) in order to access private pages. */}
            {rx_isLoggedIn &&
            (window.location.href.includes('immomove') || isEmployee) ? (
              <PrivateRoute component={App} />
            ) : (
              <Redirect
                to={{
                  pathname: PUBLIC_ROUTE.LANDING,
                }}
              />
            )}
          </Switch>
        </ErrorScreen>
      </BrowserRouter>
    </Suspense>
  );
}
