import axios, { AxiosRequestConfig } from 'axios';
import { ID } from 'definitions/constants-fe';
import Firebase from 'library/firebase';
import {
  clearToken,
  clearUserRole,
  getToken,
  setToken,
} from 'library/localStorageHelper';
import { Reporting } from 'library/sentry/reporting';
import { eventChannel } from 'redux-saga';
import {
  all,
  call,
  cancelled,
  delay,
  put,
  take,
  takeEvery,
} from 'redux-saga/effects';
import actionsApp from 'redux/global/app/actions';
import actionsImmoApp from 'redux/immoapp/actions';
import actionsImmoFonds from 'redux/immofonds/actions';
import actionsImmoMove from 'redux/immomove/actions';
import { PUBLIC_ROUTE } from 'route.constants';
import {
  CONTROL_FUNCTIONS,
  DB,
  ERROR_CODE,
  SECRETS,
  SPACE_FUNCTIONS,
  URLS,
} from '../../../../../shared/constants';
import actions from './actions';

const {
  database,
  rsfFirestore,
  refFireFunction,
  snapshotAsArray,
  firstAppAuth,
  EmailAuthProvider,
} = Firebase;

export function* login({ payload }: any) {
  if (payload) {
    yield setToken(payload);
    yield put(actions.login.success());
  } else {
    yield put(actions.login.failure());
  }
}
function* logout({ payload }: any) {
  // Cancel realtime listeners
  yield put(actionsImmoApp.cancelCommonSettings());
  yield put(actionsImmoApp.cancelMandates());
  yield put(actionsImmoApp.cancelProperties());
  yield put(actionsImmoApp.cancelMessages());
  yield put(actionsImmoApp.cancelRepairs());
  yield put(actionsImmoApp.cancelPinboards());
  yield put(actionsImmoApp.cancelVotes());
  yield put(actionsImmoApp.cancelVoteItems());
  yield put(actionsImmoApp.cancelVoteUsers());
  yield put(actionsImmoApp.cancelUsers());
  yield put(actionsImmoApp.cancelStaff());
  yield put(actionsImmoFonds.cancelImmovables());
  yield put(actionsImmoMove.cancelTerminationProcesses());
  // Clear Folder Content
  yield put(actionsImmoApp.clearFolderContent());
  // Logout
  clearToken();
  clearUserRole();
  Firebase.logout();
  yield put(actions.logout.success());
  if (payload != null) {
    window.location.href = payload;
  }
}

const authEventsChannel = eventChannel((emit) => {
  const unsubscribe = Firebase.firstAppAuth.onAuthStateChanged((user) => {
    emit({ user });
  });
  // return a function that can be used to unregister listeners when the saga is cancelled
  return unsubscribe;
});

function* checkAuthorization() {
  const token = getToken();
  if (token) {
    yield put(actions.login.success());
    // This trigger logs out the user if no FB Auth exists
    try {
      while (true) {
        const { user } = yield take(authEventsChannel);
        if (!user) {
          // If we logout because no Firebase persistence was set on login, then the token is still active --> We need to redirect immediately to avoid missingPermissions error
          if (getToken()) {
            window.location.href = PUBLIC_ROUTE.LANDING;
          }
          yield put(actions.logout());
        }
      }
    } finally {
      // unregister listener if the saga was cancelled
      // @ts-ignore
      if (yield cancelled()) {
        authEventsChannel.close();
      }
    }
  }
}

function* getUser({ payload }: any) {
  try {
    // @ts-ignore
    const docRef = yield call(
      rsfFirestore.getDocument,
      `${DB.ia_users}/${payload.uid}`,
    );
    if (!docRef.exists) {
      Reporting.Error(new Error(`User with id does not exist: ${payload.uid}`));
      yield put(actions.getUser.failure('global.generalErrorMessage'));
      yield put(actions.logout.trigger());
      return;
    }
    const user = docRef.data();
    user.id = docRef.id;
    user.uid = docRef.id;
    if (payload.currentPage === ID.immomove) {
      // @ts-ignore
      const snapshot = yield call(
        rsfFirestore.getCollection,
        database
          .collection(DB.im_termination_process)
          .where('tenant.uid', '==', payload.uid),
      );
      const data = snapshotAsArray(snapshot);
      if (data.length > 0) {
        user.tenant = data[0];
      }
    }
    yield put(actions.getUser.success(user));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.getUser.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.getUser.fulfill());
  }
}

function* setUser({ payload }: any) {
  try {
    // @ts-ignore
    yield call(rsfFirestore.updateDocument, `${DB.ia_users}/${payload.uid}`, {
      time_last_login: Firebase.FieldValue.serverTimestamp(),
    });
    yield put(actions.setUser.success());
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.setUser.failure());
  } finally {
    yield delay(1);
    yield put(actions.setUser.fulfill());
  }
}

function* resendAccessCode({ payload }: any) {
  try {
    // @ts-ignore
    const res = yield refFireFunction.httpsCallable(
      SPACE_FUNCTIONS.im_resend_access_code,
    )({
      email: payload.email,
      zip_code: payload.zip_code,
    });
    // Process was not found
    if (res.data === 301) {
      yield put(actions.resendAccessCode.failure('signintools.wrongInput'));
    } else {
      yield put(
        actions.resendAccessCode.success('process.resendAccessCodeSuccess'),
      );
      yield put(actionsApp.setModal(ID.none));
    }
  } catch (error) {
    Reporting.Error(error);
    yield put(
      actions.resendAccessCode.failure('process.resendAccessCodeError'),
    );
  } finally {
    yield delay(1);
    yield put(actions.resendAccessCode.fulfill());
  }
}

function* sendPwReset({ payload }: any) {
  try {
    payload.project_id = process.env.REACT_APP_FB_PROJECT_ID;
    const options: AxiosRequestConfig = {
      method: 'POST',
      url: `${URLS.control_cloud}/${CONTROL_FUNCTIONS.ic_password_reset_mail}?idToken=${SECRETS.access_token}`,
      data: payload,
    };
    yield axios(options);
    yield put(actions.sendPwReset.success('process.resetEmailSuccess'));
    yield put(actionsApp.setModal(ID.none));
  } catch (error: any) {
    Reporting.Error(error);
    if (error.response.status === 405) {
      yield put(actions.sendPwReset.failure('process.resetEmailNotFound'));
      return;
    }
    yield put(actions.sendPwReset.failure('process.resetEmailError'));
  } finally {
    yield delay(1);
    yield put(actions.sendPwReset.fulfill());
  }
}

export function* updateUserCommunicationSettings({ payload }: any) {
  if (firstAppAuth.currentUser == null) {
    Reporting.Error(
      new Error(`firstAppAuth.currentUser is unexpectedly null.`),
    );
    return;
  }
  // This can be updated over time for other notification settings
  const { subKey, value } = payload;
  try {
    yield database
      .collection(DB.ia_users)
      .doc(firstAppAuth.currentUser.uid)
      .update({ [`communication.${subKey}`]: value });
    yield put(
      actions.updateUserCommunicationSettings.success(
        'account.notificationSettingsUpdated',
      ),
    );
  } catch (error) {
    yield put(
      actions.updateUserCommunicationSettings.failure(
        'global.generalErrorMessage',
      ),
    );
  } finally {
    yield delay(1);
    yield put(actions.updateUserCommunicationSettings.fulfill());
  }
}

export function* updateCurrentUserSecurity({ payload }: any) {
  if (firstAppAuth.currentUser == null) {
    Reporting.Error(
      new Error(`firstAppAuth.currentUser is unexpectedly null.`),
    );
    return;
  }
  const { current_email, current_password, update, email, password } = payload;
  try {
    yield firstAppAuth.currentUser.reauthenticateWithCredential(
      EmailAuthProvider.credential(current_email, current_password),
    );
    switch (update) {
      case ID.email:
        yield database
          .collection(DB.ia_users)
          .doc(firstAppAuth.currentUser.uid)
          .update({ email: email });
        yield put(
          actions.updateCurrentUserSecurity.success('account.emailChanged'),
        );
        break;
      case ID.password:
        yield firstAppAuth.currentUser.updatePassword(password);
        yield put(
          actions.updateCurrentUserSecurity.success('account.passwordChanged'),
        );
        break;
      default:
        break;
    }
    // Close modal
    yield put(actionsApp.setModal(ID.none));
  } catch (error: any) {
    switch (error.code) {
      case ERROR_CODE.auth_wrong_password:
        yield put(
          actions.updateCurrentUserSecurity.failure('auth.wrongPassword'),
        );
        break;
      default:
        yield put(
          actions.updateCurrentUserSecurity.failure(
            'global.generalErrorMessage',
          ),
        );
        Reporting.Error(error);
        break;
    }
  } finally {
    yield delay(1);
    yield put(actions.updateCurrentUserSecurity.fulfill());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.checkAuthorization.TRIGGER, checkAuthorization),
    takeEvery(actions.login.TRIGGER, login),
    takeEvery(actions.logout.TRIGGER, logout),
    takeEvery(actions.getUser.TRIGGER, getUser),
    takeEvery(actions.setUser.TRIGGER, setUser),
    takeEvery(actions.resendAccessCode.TRIGGER, resendAccessCode),
    takeEvery(actions.sendPwReset.TRIGGER, sendPwReset),
    takeEvery(
      actions.updateUserCommunicationSettings.TRIGGER,
      updateUserCommunicationSettings,
    ),
    takeEvery(
      actions.updateCurrentUserSecurity.TRIGGER,
      updateCurrentUserSecurity,
    ),
  ]);
}
