import antdDE from 'antd/lib/locale/de_DE';
import { TRANSLATIONS_DE } from '../../../../../shared/i18n/de';

const deLang = {
  messages: {
    ...TRANSLATIONS_DE,
  },
  antd: antdDE,
  id: 'de',
  locale: 'de-DE',
};

export default deLang;
