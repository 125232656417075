import { Button, notification, Space } from 'antd';
import { translate } from 'components/Utility/i18n';
import { ID, SettingsScreen, TAB_IDENTIFIER } from 'definitions/constants-fe';
import {
  setLicenseNotice,
  shouldShowLicenseNotice,
} from 'library/localStorageHelper';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import actions from 'redux/immoapp/actions';
import { RootState } from 'redux/root-saga';
import { TOOL } from 'route.constants';

export default (): JSX.Element => {
  // File members
  const mDispatch = useDispatch();
  const history = useHistory();

  // Redux state
  const { rx_licenseObjects } = useSelector(
    (state: RootState) => state.ImmoApp,
  );

  // Did mount
  React.useEffect(() => {
    mDispatch(actions.getLicenseObjects(false));
  }, [mDispatch]);

  React.useEffect(() => {
    if (rx_licenseObjects == null) {
      return;
    }
    if (
      rx_licenseObjects.licensed_objects > -1 &&
      process.env.REACT_APP_IA_SHOW_LICENSE_NOTIFICATION === String(true) &&
      shouldShowLicenseNotice() &&
      rx_licenseObjects.active_objects > rx_licenseObjects.licensed_objects
    ) {
      notification.warning({
        key: ID.ia_license_notification,
        placement: 'bottomRight',
        message: translate('license.limitExceededTitle'),
        description: [
          translate('license.limitExceededText1', {
            activeObjects: rx_licenseObjects.active_objects,
            allowedObjects: rx_licenseObjects.licensed_objects,
          }),
        ],
        duration: 0,
        btn: (
          <Space size="small">
            <Button
              size="small"
              onClick={() => {
                setLicenseNotice();
                notification.destroy(ID.ia_license_notification);
                history.push(
                  `/${TOOL.SETTINGS}${TAB_IDENTIFIER}${SettingsScreen.settings_tabs.accounts}`,
                );
              }}
            >
              {translate('global.moreInfo')}
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                setLicenseNotice();
                notification.destroy(ID.ia_license_notification);
              }}
            >
              {translate('global.hide')}
            </Button>
          </Space>
        ),
        // This is only executed when clicking the close icon, not when clicking the buttons.
        onClose: () => setLicenseNotice(),
      });
    }
  }, [rx_licenseObjects, history]);

  return <></>;
};
