import { createRoutine } from 'redux-saga-routines';

const DOCUMENT = 'IM_';
const actions = {
  // --- START REALTIME ---
  subscribeTerminationProcesses: createRoutine(
    DOCUMENT + 'SUBSCRIBE_TERMINATION_PROCESSES',
  ),
  cancelTerminationProcesses: createRoutine(
    DOCUMENT + 'CANCEL_TERMINATION_PROCESSES',
  ),
  subscribeStaffImmoMove: createRoutine(DOCUMENT + 'SUBSCRIBE_STAFF_IMMOMOVE'),
  cancelStaffImmoMove: createRoutine(DOCUMENT + 'CANCEL_STAFF_IMMOMOVE'),
  // --- END REALTIME ---
  getTerminationProcessDetails: createRoutine(
    DOCUMENT + 'GET_TERMINATION_PROCESS_DETAILS',
  ),
  getCommonFiles: createRoutine(DOCUMENT + 'GET_COMMON_FILES'),
  terminationAction: createRoutine(DOCUMENT + 'TERMINATION_ACTION'),
  updateTerminationStatus: createRoutine(
    DOCUMENT + 'UPDATE_TERMINATION_STATUS',
  ),
  resendAccess: createRoutine(DOCUMENT + 'RESEND_ACCESS'),
  sendVoucher: createRoutine(DOCUMENT + 'SEND_VOUCHER'),
  getUserAndProcess: createRoutine(DOCUMENT + 'GET_USER_AND_PROCESS'),
  saveAnswers: createRoutine(DOCUMENT + 'SAVE_ANSWERS'),
};
export default actions;
