import * as Sentry from '@sentry/react';
import 'index.css';
import { Reporting } from 'library/sentry/reporting';
// @ts-ignore
import { createRoot } from 'react-dom/client';
import packageJson from '../package.json';
import App from './app';
import * as serviceWorker from './serviceWorker';

const envCheck = ['immodigi-dev', 'immodigi-demodata'].every(
  (ele) => process.env.REACT_APP_FB_PROJECT_ID !== ele,
);
const devCheck = ['localhost', '127.0.0.1'].every(
  (ele) => window.location.hostname !== ele,
);
if (envCheck && devCheck) {
  Sentry.init({
    dsn: 'https://78e623361e134d5ca3c4979ead526072@o274448.ingest.sentry.io/1501789',
    environment: process.env.REACT_APP_FB_PROJECT_ID,
    release: packageJson.version,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
    ],
  });
}

// START WORKAROUND to deal with this error
// NotFoundError: Failed to execute 'removeChild' on 'Node': The node to be removed is not a child of this node.
// https://busybytes-gmbh.sentry.io/issues/4880328366/?project=1501789&query=environment%3Aimmodigi-keller&referrer=issue-stream&statsPeriod=14d&stream_index=0
// https://github.com/facebook/react/issues/11538#issuecomment-417504600
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  // @ts-ignore
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        Reporting.Warning(`Cannot remove a child from a different parent.`, [
          // @ts-ignore
          `child outerHTML: ${child?.outerHTML}`,
        ]);
      }
      return child;
    }
    // @ts-ignore
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  // @ts-ignore
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        Reporting.Warning(
          `Cannot insert before a reference node from a different parent.`,
          // @ts-ignore
          [`referenceNode outerHTML: ${referenceNode?.outerHTML}.`],
        );
      }
      return newNode;
    }
    // @ts-ignore
    return originalInsertBefore.apply(this, arguments);
  };
}
// END WORKAROUND

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
