import { ID } from 'definitions/constants-fe';
import { showMessage } from 'library/functionHelper';
import { RootState_GLAuth } from 'redux/root-saga';
import actions from './actions';

const initState: RootState_GLAuth = {
  mLoading: [],
  rx_isLoggedIn: false,
  rx_userAccount: null,
  rx_loading: false,
};

export default function authReducer(state = initState, { type, payload }: any) {
  const typeName = type.split('/')[0];
  switch (type) {
    // LOGIN
    case actions.login.SUCCESS:
      return { ...state, rx_isLoggedIn: true };
    case actions.login.FAILURE:
      return { ...state, rx_isLoggedIn: false };

    // LOGOUT
    case actions.logout.SUCCESS:
      return { ...state, rx_isLoggedIn: false };

    // SETUSER
    case actions.setUser.TRIGGER:
      return { ...state, rx_userAccount: payload };

    // GETUSER
    case actions.getUser.TRIGGER:
      state.mLoading.push(typeName);
      return { ...state, rx_loading: state.mLoading.length > 0 };
    case actions.getUser.SUCCESS:
      return { ...state, rx_userAccount: payload };
    case actions.getUser.FAILURE:
      showMessage(payload, null, ID.error);
      return { ...state, rx_userAccount: null };
    case actions.getUser.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return { ...state, rx_loading: state.mLoading.length > 0 };

    // RESEND ACCESS CODE
    case actions.resendAccessCode.TRIGGER:
      state.mLoading.push(typeName);
      return { ...state, rx_loading: state.mLoading.length > 0 };
    case actions.resendAccessCode.SUCCESS:
      showMessage(payload, null, ID.success);
      return { ...state };
    case actions.resendAccessCode.FAILURE:
      showMessage(payload, null, ID.error);
      return { ...state };
    case actions.resendAccessCode.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return { ...state, rx_loading: state.mLoading.length > 0 };

    // SEND PW RESET
    case actions.sendPwReset.TRIGGER:
      state.mLoading.push(typeName);
      return { ...state, rx_loading: state.mLoading.length > 0 };
    case actions.sendPwReset.SUCCESS:
      showMessage(payload, null, ID.success);
      return { ...state };
    case actions.sendPwReset.FAILURE:
      showMessage(payload, null, ID.error);
      return { ...state };
    case actions.sendPwReset.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return { ...state, rx_loading: state.mLoading.length > 0 };

    // AUTOMATIC EMAIL CHANGE SETTINGS
    case actions.updateUserCommunicationSettings.TRIGGER:
      state.mLoading.push(typeName);
      return { ...state, rx_loading: state.mLoading.length > 0 };
    case actions.updateUserCommunicationSettings.SUCCESS:
      showMessage(payload, null, ID.success);
      return { ...state };
    case actions.updateUserCommunicationSettings.FAILURE:
      showMessage(payload, null, ID.error);
      return { ...state };
    case actions.updateUserCommunicationSettings.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return { ...state, rx_loading: state.mLoading.length > 0 };

    // AUTOMATIC EMAIL CHANGE SETTINGS
    case actions.updateCurrentUserSecurity.TRIGGER:
      state.mLoading.push(typeName);
      return { ...state, rx_loading: state.mLoading.length > 0 };
    case actions.updateCurrentUserSecurity.SUCCESS:
      showMessage(payload, null, ID.success);
      return { ...state };
    case actions.updateCurrentUserSecurity.FAILURE:
      showMessage(payload, null, ID.error);
      return { ...state };
    case actions.updateCurrentUserSecurity.FULFILL:
      state.mLoading = state.mLoading.filter((e) => e !== typeName);
      return { ...state, rx_loading: state.mLoading.length > 0 };

    default:
      return state;
  }
}
