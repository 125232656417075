import antdIt from 'antd/lib/locale/it_IT';
import { TRANSLATIONS_IT } from '../../../../../shared/i18n/it';

const itLang = {
  messages: {
    ...TRANSLATIONS_IT,
  },
  antd: antdIt,
  id: 'it',
  locale: 'it-IT',
};

export default itLang;
