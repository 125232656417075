import axios from 'axios';
import { FileUploadProps, ID } from 'definitions/constants-fe';
import Firebase from 'library/firebase';
import {
  addFilesTimeCreated,
  handleTimeStamps,
  showMessage,
} from 'library/functionHelper';
import { Reporting } from 'library/sentry/reporting';
import _ from 'lodash';
import omit from 'lodash/omit'; // TODO optimize
import {
  all,
  call,
  cancel,
  delay,
  fork,
  put,
  take,
  takeEvery,
} from 'redux-saga/effects';
import actionsApp from 'redux/global/app/actions';
import { DB, SPACE_FUNCTIONS, STORAGE } from '../../../../shared/constants';
import actions from './actions';

import {
  DefaultBankAccountValues,
  DefaultComponentValues,
  DefaultImmovableValues,
  IFWizard,
} from 'assets/definitions/IFWizard';
import { calculateComponentRenovationCost } from 'containers/immofonds/Home/Parts/ComponentCalculations';

const {
  database,
  rsfFirestore,
  refFireFunction,
  getAuthToken,
  snapshotAsArray,
} = Firebase;

// REALTIME
let listenerImmovables = null;
function* subscribeImmovables() {
  try {
    // This makes sure that the database listener is only active once
    if (listenerImmovables?.isRunning() === true) {
      return;
    }
    yield put(actions.subscribeImmovables.request());
    listenerImmovables = yield fork(
      rsfFirestore.syncCollection,
      DB.if_immovables,
      {
        successActionCreator: actions.subscribeImmovables.success,
        failureActionCreator: actions.subscribeImmovables.failure,
        transform: (payload) => snapshotAsArray(payload, true),
      },
    );
    // If cancel is called, the code continues to execute
    yield take(actions.cancelImmovables.TRIGGER);
    // And we cancel the database listener
    yield cancel(listenerImmovables);
    listenerImmovables = null;
  } catch (error) {
    Reporting.Error(error);
    listenerImmovables = null;
    yield put(
      actions.subscribeImmovables.failure('global.generalErrorMessage'),
    );
  } finally {
  }
}

function* getImmovableDetails({ payload }) {
  const { immovableId, allowCachedData } = payload;
  try {
    const snapshot = yield call(
      rsfFirestore.getDocument,
      database.collection(DB.if_immovables).doc(immovableId),
    );
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { imageUrl, ...data } = snapshot.data();
    if (allowCachedData && !_.isEmpty(data.cloud_cache)) {
      yield put(actions.getImmovableDetails.success(data));
    }
    const calculatedData = yield refFireFunction.httpsCallable(
      SPACE_FUNCTIONS.if_calculation_immovable,
    )({
      immovable_ref: immovableId,
    });
    data.cloud_cache = calculatedData.data;
    yield put(actions.getImmovableDetails.success(data));
    yield database.collection(DB.if_immovables).doc(immovableId).set(data);
  } catch (error) {
    Reporting.Error(error);
    yield put(
      actions.getImmovableDetails.failure('global.generalErrorMessage'),
    );
  } finally {
    yield delay(1);
    yield put(actions.getImmovableDetails.fulfill());
  }
}

const updateRecords = async (data, documentId) => {
  try {
    data.images.map((ele) => {
      delete ele.url;
      return ele;
    });
    await database.collection(DB.if_components).doc(documentId).set(data);
    return 1;
  } catch (error) {
    return error;
  }
};

function* getComponentDetails({ payload }) {
  const componentId = payload.toString();
  try {
    const snapshot = yield call(
      rsfFirestore.getDocument,
      database.collection(DB.if_components).doc(componentId),
    );
    const data = snapshot.data();
    // get the immovable street: start
    const immovableSnapshot = yield call(
      rsfFirestore.getDocument,
      database.collection(DB.if_immovables).doc(data.immovable_ref),
    );
    data.immovable_street = immovableSnapshot.data().address.street;
    // get the immovable street: end
    if (data.calculated_data && !_.isEmpty(data.calculated_data)) {
      yield put(actions.getComponentDetails.success(data));
    }
    const calculatedData = yield refFireFunction.httpsCallable(
      SPACE_FUNCTIONS.if_calculation_component,
    )({
      component_id: componentId,
    });
    data.calculated_data = calculatedData.data;
    yield updateRecords(data, componentId);
    yield put(actions.getComponentDetails.success(data));
  } catch (error) {
    Reporting.Error(error);
    yield put(
      actions.getComponentDetails.failure('global.generalErrorMessage'),
    );
  } finally {
    yield delay(1);
    yield put(actions.getComponentDetails.fulfill());
  }
}

function* immovableAction({ payload }) {
  // selectedImmovable for insert image only
  const { actionName, key, immovableImage, ...record } = payload;
  let promises = [];
  let msgRef = null;
  try {
    switch (actionName) {
      case ID.delete:
        msgRef = showMessage('immovable.deleting', null, ID.loading, ID.key);
        yield refFireFunction.httpsCallable(
          SPACE_FUNCTIONS.if_delete_immovable,
        )({
          immovable_ref: key,
        });
        msgRef();
        yield put(actions.immovableAction.success('mandate.deleted'));
        break;
      case ID.copy:
        msgRef = showMessage('immovable.copying', null, ID.loading, ID.key);
        yield refFireFunction.httpsCallable(
          SPACE_FUNCTIONS.if_duplicate_immovable,
        )({
          immovable_ref: key,
        });
        msgRef();
        yield put(actions.immovableAction.success('immovable.duplicated'));
        break;
      case ID.update:
      case ID.update_date:
        if (record.accounts_updated) {
          record.accounts_updated = handleTimeStamps(record.accounts_updated);
        }
        if (actionName === ID.update_date) {
          yield database
            .collection(DB.if_immovables)
            .doc(key)
            .update({ accounts_updated: record.accounts_updated });
        } else {
          if (immovableImage != null) {
            showMessage('upload.inprogress', null, ID.loading, ID.key);
            const files = yield Firebase.adminFilesToStorageFiles(
              STORAGE.immovable_image,
              immovableImage,
            );
            showMessage('global.fileUploaded', null, ID.success, ID.key);
            record.image = files[0];
          } else {
            record.image = record.image ?? [];
          }
          record.time_created = handleTimeStamps(record.time_created);
          record.time_updated = handleTimeStamps();
          // Set correct time_created for all accounts (TODO: can this be done in cloud function instead?)
          promises = [];
          record.cloud_cache.accounts_data.forEach((account) => {
            account.data.time_created = new Date(
              account.data.time_created._seconds * 1000,
            );
            promises.push(
              database
                .collection(DB.if_bank_accounts)
                .doc(account.id)
                .set(account.data),
            );
          });
          yield Promise.all(promises);
          if (record.removeBankAccount && record.removeBankAccount.length > 0) {
            // Delete all accounts that were removed in frontend
            const promises = [];
            record.removeBankAccount.forEach((account) => {
              promises.push(
                database.collection(DB.if_bank_accounts).doc(account).delete(),
              );
            });
            yield Promise.all(promises);
          }
          yield call(rsfFirestore.setDocument, `${DB.if_immovables}/${key}`, {
            ...omit(record, ['key', 'removeBankAccount', 'imageUrl']),
          });
        }
        yield put(actions.immovableAction.success('mandate.updated'));
        break;
      case ID.insert:
        if (immovableImage != null) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          const mandateImage = yield Firebase.getFiles([immovableImage]);
          const dbPath = yield Firebase.adminFilesToStorageFiles(
            STORAGE.immovable_image,
            mandateImage,
            {
              ...FileUploadProps,
              imageFromDifferentFolder: STORAGE.mandate_images,
            },
          );
          showMessage('global.fileUploaded', null, ID.success, ID.key);
          record.data.image = dbPath[0];
        }
        const immovable = yield call(
          rsfFirestore.addDocument,
          DB.if_immovables,
          {
            ...DefaultImmovableValues,
            ...record.data,
            current_deposit: record.ef.current_deposit,
            yearly_correction_threshold: record.ef.current_deposit * 0.05,
            time_created: handleTimeStamps(),
            time_updated: handleTimeStamps(),
            accounts_updated: handleTimeStamps(),
          },
        );
        yield call(rsfFirestore.addDocument, DB.if_bank_accounts, {
          ...DefaultBankAccountValues,
          immovable_ref: immovable.id,
          balance: record.ef.current_balance,
          time_created: handleTimeStamps(),
          time_updated: handleTimeStamps(),
        });
        promises = [];
        Object.keys(record.components).forEach((component) => {
          const componentData = {
            ...DefaultComponentValues,
            immovable_ref: immovable.id,
            name: IFWizard.components[component].name,
            type: component,
            subtype: record.components[component].subtype,
            renovation_date: record.data.construction_date,
            renewal_date:
              record.data.construction_date +
              IFWizard.components[component].subtypes[
                record.components[component].subtype
              ].lifespan,
            time_created: handleTimeStamps(),
            time_updated: handleTimeStamps(),
          };
          componentData.factor = calculateComponentRenovationCost(
            record.data,
            componentData,
          );
          promises.push(
            database.collection(DB.if_components).add(componentData),
          );
        });
        yield Promise.all(promises);
        yield put(actions.immovableAction.success('immovable.added'));
        break;
      default:
        Reporting.Error(new Error());
        break;
    }
    // In case of success close modal
    yield put(actionsApp.setModal(ID.none));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.immovableAction.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.immovableAction.fulfill());
    if (actionName === ID.update) {
      yield put(
        actions.getImmovableDetails({
          immovableId: key,
          allowCachedData: false,
        }),
      );
    }
  }
}

function* bankAccountAction({ payload }) {
  const { actionName, key, ...record } = payload;
  try {
    record.time_updated = handleTimeStamps();
    switch (actionName) {
      case ID.update:
        record.time_created = new Date(record.time_created._seconds * 1000);
        yield call(
          rsfFirestore.setDocument,
          `${DB.if_bank_accounts}/${key}`,
          record,
        );
        yield put(
          actions.bankAccountAction.success('immovable.bankAccountUpdated'),
        );
        break;
      case ID.delete:
        yield call(
          rsfFirestore.deleteDocument,
          `${DB.if_bank_accounts}/${key}`,
        );
        yield put(actions.bankAccountAction.success('immovable.bankDeleted'));
        break;
      case ID.copy:
        record.time_created = handleTimeStamps();
        yield call(rsfFirestore.addDocument, DB.if_bank_accounts, record);
        yield put(
          actions.bankAccountAction.success('immovable.bankAccountCopied'),
        );
        break;
      case ID.insert:
        record.time_created = handleTimeStamps();
        yield call(rsfFirestore.addDocument, DB.if_bank_accounts, record);
        yield put(
          actions.bankAccountAction.success('immovable.bankAccountAdded'),
        );
        break;
      default:
        Reporting.Error(new Error());
        break;
    }
    yield database
      .collection(DB.if_immovables)
      .doc(record.immovable_ref)
      .update({ accounts_updated: handleTimeStamps() });
    // In case of success close modal
    yield put(actionsApp.setModal(ID.none));
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.bankAccountAction.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.bankAccountAction.fulfill());
    // Refresh data
    yield put(
      actions.getImmovableDetails({
        immovableId: record.immovable_ref,
        allowCachedData: false,
      }),
    );
  }
}

function* componentAction({ payload }) {
  const { actionName, key, filesArray, ...record } = payload;
  const hasNewFiles = filesArray?.some(
    (f) => f.size != null || f.originFileObj != null || f.isEdited === true,
  );
  const hasChangedFiles =
    filesArray != null &&
    record.images != null &&
    filesArray.length !== record.images.length;
  try {
    let componentId = key;
    switch (actionName) {
      case ID.update:
        if (hasNewFiles || hasChangedFiles) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.component_image,
            filesArray,
          );
          showMessage(
            `global.${
              filesArray.length > record.images?.length
                ? 'filesUploaded'
                : 'filesUpdated'
            }`,
            null,
            ID.success,
            ID.key,
          );
          record.images = addFilesTimeCreated(files);
        } else if (record.images == null || filesArray.length === 0) {
          record.images = [];
        }
        record.time_created = handleTimeStamps(record.time_created);
        record.time_updated = handleTimeStamps();
        yield call(
          rsfFirestore.setDocument,
          `${DB.if_components}/${key}`,
          record,
        );
        yield put(actions.componentAction.success('component.updated'));
        break;
      case ID.delete:
        yield call(rsfFirestore.deleteDocument, `${DB.if_components}/${key}`);
        yield put(actions.componentAction.success('component.deleted'));
        yield put(actions.getComponentDetails.success(null));
        break;
      case ID.copy:
        if (filesArray?.length > 0) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.component_image,
            filesArray,
            { ...FileUploadProps, isCopy: true },
          );
          showMessage('global.filesUploaded', null, ID.success, ID.key);
          record.images = addFilesTimeCreated(files);
        } else {
          record.images = [];
        }
        record.time_created = handleTimeStamps();
        record.time_updated = handleTimeStamps();
        componentId = (yield call(
          rsfFirestore.addDocument,
          DB.if_components,
          record,
        )).id;
        yield put(actions.componentAction.success('immovable.componentCopied'));
        break;
      case ID.insert:
        if (filesArray?.length > 0) {
          showMessage('upload.inprogress', null, ID.loading, ID.key);
          const files = yield Firebase.adminFilesToStorageFiles(
            STORAGE.component_image,
            filesArray,
          );
          showMessage('global.filesUploaded', null, ID.success, ID.key);
          record.images = addFilesTimeCreated(files);
        } else {
          record.images = [];
        }
        record.time_created = handleTimeStamps();
        record.time_updated = handleTimeStamps();
        componentId = (yield call(
          rsfFirestore.addDocument,
          DB.if_components,
          record,
        )).id;
        yield put(actions.componentAction.success('immovable.componentAdded'));
        break;
      default:
        Reporting.Error(new Error());
        break;
    }
    if (actionName !== ID.delete) {
      // In case of success close modal
      yield put(actionsApp.setModal(ID.none));
      const calculatedData = yield refFireFunction.httpsCallable(
        SPACE_FUNCTIONS.if_calculation_component,
      )({
        component_id: componentId,
      });
      yield database
        .collection(DB.if_components)
        .doc(componentId)
        .update({ calculated_data: calculatedData.data });
      yield put(actions.getComponentDetails(componentId));
    }
  } catch (error) {
    Reporting.Error(error);
    yield put(actions.componentAction.failure('global.generalErrorMessage'));
  } finally {
    yield delay(1);
    yield put(actions.componentAction.fulfill());
    // Refresh data
    if (record.immovable_ref != null) {
      yield put(
        actions.getImmovableDetails({
          immovableId: record.immovable_ref,
          allowCachedData: false,
        }),
      );
    }
  }
}

function* downloadPdf({ payload }) {
  try {
    yield put(actions.downloadPdf.success(20));
    const start = new Date().getTime();
    const idToken = yield getAuthToken();
    if (idToken == null) {
      throw new Error('GetAuthToken returned null.');
    }
    yield put(actions.downloadPdf.success(30));
    const options = {
      method: 'POST',
      url: `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/${SPACE_FUNCTIONS.if_pdf_report}`,
      data: payload,
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      responseType: 'blob',
      timeout: 45000,
    };
    let response = null;
    try {
      response = yield axios(options);
    } catch (error) {
      // 301: filesize is too big. Firebase functions have limit of 10 MB
      yield put(
        actions.downloadPdf.failure({
          sizeError:
            error.response && parseInt(error.response.status) === 301
              ? true
              : false,
        }),
      );
      return;
    }
    console.info('PDF creation time', new Date().getTime() - start);
    yield put(actions.downloadPdf.success(response));
  } catch (error) {
    showMessage('global.generalErrorMessage', null, ID.error);
    Reporting.Error(error);
    actions.downloadPdf.failure({ sizeError: false });
  } finally {
    yield delay(1);
    yield put(actions.downloadPdf.fulfill());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.subscribeImmovables.TRIGGER, subscribeImmovables),
    takeEvery(actions.getImmovableDetails.TRIGGER, getImmovableDetails),
    takeEvery(actions.getComponentDetails.TRIGGER, getComponentDetails),
    takeEvery(actions.immovableAction.TRIGGER, immovableAction),
    takeEvery(actions.bankAccountAction.TRIGGER, bankAccountAction),
    takeEvery(actions.componentAction.TRIGGER, componentAction),
    takeEvery(actions.downloadPdf.TRIGGER, downloadPdf),
  ]);
}
