import germanIcon from 'assets/images/flag/german.svg';
import italianIcon from 'assets/images/flag/italy.svg';
import englishIcon from 'assets/images/flag/uk.svg';
import { DEFAULT_LANGUAGE_ID } from '../../../shared/constants';
// import frenchIcon from "assets/images/flag/france.svg";

export const languages = [
  {
    id: 'de',
    text: 'Deutsch',
    icon: germanIcon,
  },
  {
    id: 'en',
    text: 'English',
    icon: englishIcon,
  },
  /* {
      id: "fr",
      text: "French",
      icon: frenchIcon
    }, */
  {
    id: 'it',
    text: 'Italiano',
    icon: italianIcon,
  },
];

export const getCurrentLanguage = (langId: string) => {
  return (
    languages.find((ele) => ele.id === langId) ??
    languages.find((ele) => ele.id === DEFAULT_LANGUAGE_ID)
  );
};
