import { translate } from 'components/Utility/i18n';
import React from 'react';
import packageJson from '../../package.json';

export default {
  siteName: process.env.REACT_APP_PLATFORM_TITLE,
  siteIcon: 'ion-flash',
  footerText: (
    <span>
      <a href="https://immodigi.ch" target="_blank" rel="noopener noreferrer">
        ImmoDigi AG
      </a>
      {' - '}
      {translate('footer.allRightsReserved')}
      <p>
        ({translate('global.version')} {packageJson.version})
      </p>
    </span>
  ),
};
