import { ConfigProvider } from 'antd';
import 'components/Utility/i18n';
import { changeLanguage } from 'components/Utility/i18n';
import { getCurrentLanguage, languages } from 'config/language.config';
import themes, { themeConfig } from 'config/theme/theme.config';
import { AppLocale } from 'config/translation';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/it';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/root-saga';
import { ThemeProvider } from 'styled-components';
import { DEFAULT_LANGUAGE_ID } from '../../shared/constants';
dayjs.extend(updateLocale);

export default ({ children }: any): any => {
  // Redux state
  const { rx_userAccount } = useSelector((state: RootState) => state.GL_Auth);

  const currentAppLocale =
    // @ts-ignore
    AppLocale[
      getCurrentLanguage(
        rx_userAccount?.language ||
          process.env.REACT_APP_PLATFORM_LANG ||
          DEFAULT_LANGUAGE_ID,
      )!.id
    ];

  changeLanguage(currentAppLocale.id);

  // Set dayjs language.
  // Note: must import the languages.
  dayjs.locale(currentAppLocale.id);

  for (const language of languages) {
    dayjs.updateLocale(language.id, {
      weekStart: 1,
    });
  }

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      {/* @ts-ignore */}
      <ThemeProvider theme={themes[themeConfig.theme]}>
        {children}
      </ThemeProvider>
    </ConfigProvider>
  );
};
