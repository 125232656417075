import antdEn from 'antd/lib/locale/en_US';
import { TRANSLATIONS_EN } from '../../../../../shared/i18n/en';

const EnLang = {
  messages: {
    ...TRANSLATIONS_EN,
  },
  antd: antdEn,
  id: 'en',
  locale: 'en-US',
};

export default EnLang;
