import { Button } from 'antd';
import { translate } from 'components/Utility/i18n';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import actions from 'redux/global/auth/actions';

export default ({
  backExtraHandler,
  logoutExtraHandler,
}: ActionButtonsProps): JSX.Element => {
  // File members
  const mDispatch = useDispatch();
  const history = useHistory();
  const [mDisabled, mSetDisabled] = React.useState(false); // button debouncer

  return (
    <>
      <Button
        key={1}
        disabled={mDisabled}
        type="primary"
        onClick={() => {
          mSetDisabled(true);
          if (backExtraHandler != null) {
            backExtraHandler();
          }
          history.go(-1);
        }}
      >
        {translate('global.back')}
      </Button>
      <Button
        key={2}
        disabled={mDisabled}
        onClick={() => {
          mSetDisabled(true);
          if (logoutExtraHandler != null) {
            logoutExtraHandler();
          }
          mDispatch(actions.logout());
        }}
      >
        {translate('global.logout')}
      </Button>
    </>
  );
};

type ActionButtonsProps = {
  backExtraHandler?: () => void;
  logoutExtraHandler?: () => void;
};
