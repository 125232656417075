import { MessageType } from 'antd/lib/message/interface';
import { AxiosResponse } from 'axios';
import {
  TAdminFetchLimit,
  TAllMandateData,
  TCreateUsersResult,
  TDownloadVoteResult,
  TFolderContent,
} from 'definitions/types-fe';
import { all } from 'redux-saga/effects';
import {
  CMandate,
  CMessageReport,
  CPerson,
  CPinboardReport,
  CProperty,
  CRepairReport,
  CUser,
  CVote,
  CVoteItem,
  CVoteUser,
} from '../../../shared/classes';
import {
  ICommonSettings,
  ICommunicationReceiver,
  IFaqSection,
  IFileManagerSettings,
  ISettings,
  IStorageFile,
  TEmailDeliveryResult,
} from '../../../shared/types';
import GL_App from './global/app/sagas';
import GL_Auth from './global/auth/sagas';
import ImmoApp from './immoapp/sagas';
import ImmoFonds from './immofonds/sagas';
import ImmoMove from './immomove/sagas';

export interface RootState_ImmoApp {
  mLoading: string[];
  mMsgRef: MessageType | null;
  rx_error: any;
  rx_settingsData: ISettings | null;
  rx_commonSettings: ICommonSettings | null;
  rx_filemanagerSettings: IFileManagerSettings | null;
  rx_rootFolders: any;
  rx_properties: CProperty[];
  rx_remoteConfigUpdated: number;
  rx_folderContent: TFolderContent;
  rx_emailDeliveryResults: TEmailDeliveryResult[];
  rx_statisticsData: any;
  rx_licenseObjects: {
    active_objects: number;
    licensed_objects: number;
    total_objects: number;
  } | null;
  rx_reportReceivers: ICommunicationReceiver[] | null;
  rx_newAccountPassword: string | null;
  rx_votes: CVote[];
  rx_voteItems: CVoteItem[];
  rx_downloadVoteResultsData: TDownloadVoteResult | null;
  rx_downloadVoteProtocolData: AxiosResponse | null;
  rx_messages: {
    public: CMessageReport[];
    private: CMessageReport[];
    merged: CMessageReport[];
  };
  rx_repairs: {
    public: CRepairReport[];
    private: CRepairReport[];
    merged: CRepairReport[];
  };
  rx_pinboards: {
    public: CPinboardReport[];
    private: CPinboardReport[];
    merged: CPinboardReport[];
  };
  rx_users: CUser[];
  rx_sendHandymanPreviewMailStatus: string;
  rx_sendExternalPreviewMailStatus: string;
  rx_loading: boolean;
  rx_mandates: CMandate[];
  rx_allMandateData: TAllMandateData;
  rx_warrantReceivers: CUser[] | null;
  rx_voteUsers: CVoteUser[];
  rx_fetchTimeLimit: TAdminFetchLimit;
  rx_infocenterFAQSections: IFaqSection[];
  rx_infocenterFiles: IStorageFile[];
  rx_personData: CPerson | null;
  rx_mandateProperties: CProperty[];
  rx_createUsersNotification: TCreateUsersResult;
  rx_excelMasterDataResult: boolean | string | null;
  rx_forwardEmailResult: any;
  rx_remoteConfigAccessComments: boolean;
  rx_remoteConfigAccessPinboard: boolean;
  rx_remoteConfigAccessDocuments: boolean;
  rx_remoteConfigAccessVoteItemSubmit: boolean;
  rx_logoUpdateCounter: number;
  rx_immoMoveStatisticsData: any;
  rx_immoMoveFiles: IStorageFile[];
}

export interface RootState_GLApp {
  rx_collapsed: boolean;
  rx_modalStatus: string;
  rx_height: number;
  rx_view: string;
  rx_notificationStatus: string;
  rx_notificationData: any;
  rx_introPdf: {
    data: Buffer | null;
    title: string | null;
    status: string;
  } | null;
}

export interface RootState_GLAuth {
  mLoading: string[];
  rx_isLoggedIn: boolean;
  rx_loading: boolean;
  rx_userAccount: CUser | null;
}

export interface RootState {
  ImmoApp: RootState_ImmoApp;
  GL_App: RootState_GLApp;
  GL_Auth: RootState_GLAuth;
}

export default function* rootSaga(_: any) {
  yield all([GL_App(), GL_Auth(), ImmoApp(), ImmoFonds(), ImmoMove()]);
}
