import Icon from '@ant-design/icons';
import { Menu } from 'antd';
import { DesktopMenu } from 'assets/styles/globalStyledComponents';
import { translate } from 'components/Utility/i18n';
import { ID } from 'definitions/constants-fe';
import { getUserRole } from 'library/localStorageHelper';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RootState } from 'redux/root-saga';
import { CUser } from '../../../../../shared/classes';
import { ROUTES, routesExtraField } from '../App/AppRouter';

interface ClassPropsDirect {
  routechanged: boolean;
}

interface ClassPropsRedux {
  current: string;
  location: any;
  history: any;
  rx_userAccount: CUser | null;
  rx_remoteConfigAccessPinboard: boolean;
  rx_remoteConfigAccessDocuments: boolean;
}

type ClassProps = ClassPropsDirect & ClassPropsRedux;

interface ClassState {
  current: string;
}

class HorizontalMenu extends Component<ClassProps, ClassState> {
  constructor(props: ClassPropsDirect) {
    // @ts-ignore
    super(props);
    this.state = {
      current: '',
    };
  }

  componentDidMount() {
    const { pathname } = this.props.location;
    let current = '';
    const userRole = getUserRole(false);
    ROUTES.filter(
      (ele) =>
        ele.access(
          userRole ?? '-',
          routesExtraField(
            ele,
            this.props.rx_remoteConfigAccessPinboard,
            this.props.rx_remoteConfigAccessDocuments,
          ),
        ) && ele.isMenuItem,
    ).forEach((value) => {
      if (pathname.indexOf(value.path) !== -1) {
        current = value.path;
      }
    });
    this.setState({ current });
  }

  componentDidUpdate(prevProps: ClassProps) {
    if (this.props.routechanged !== prevProps.routechanged) {
      const { pathname } = this.props.location;
      let current = '';
      const userRole = getUserRole(false);
      ROUTES.filter(
        (ele) =>
          ele.access(
            userRole ?? '-',
            routesExtraField(
              ele,
              this.props.rx_remoteConfigAccessPinboard,
              this.props.rx_remoteConfigAccessDocuments,
            ),
          ) && ele.isMenuItem,
      ).forEach((value) => {
        if (pathname.indexOf(value.path) !== -1) {
          current = value.path;
        }
      });
      this.setState({ current });
    }
  }

  render() {
    const { current } = this.state;
    const url = this.props.history.location.pathname.split('/');
    const userRole = getUserRole(false);

    return (
      <DesktopMenu>
        {/* Menu component (Header menu) start */}
        {url.includes(ID.immoapp) && (
          <Menu
            selectedKeys={[current]}
            mode="horizontal"
            items={ROUTES.filter(
              (ele) =>
                ele.access(
                  userRole ?? '-',
                  routesExtraField(
                    ele,
                    this.props.rx_remoteConfigAccessPinboard,
                    this.props.rx_remoteConfigAccessDocuments,
                  ),
                ) && ele.isMenuItem,
            ).map((ele) => {
              return {
                key: ele.path,
                label: (
                  <>
                    <div>
                      {ele.icon && (
                        <Icon
                          component={ele.icon}
                          className="mb-1 !text-[25px]"
                        />
                      )}
                      <div>{translate(ele.label)}</div>
                    </div>
                  </>
                ),
                onClick: () =>
                  this.props.history.push({
                    pathname: ele.path,
                  }),
                style: current.includes(ele.path)
                  ? { backgroundColor: 'rgba(0, 0, 0, 0.25)' }
                  : undefined,
              };
            })}
          />
        )}
        {/* Menu component (Header menu) end */}
      </DesktopMenu>
    );
  }
}

export default withRouter(
  // @ts-ignore
  connect((state: RootState) => ({
    rx_remoteConfigAccessPinboard: state.ImmoApp.rx_remoteConfigAccessPinboard,
    rx_remoteConfigAccessDocuments:
      state.ImmoApp.rx_remoteConfigAccessDocuments,
    rx_userAccount: state.GL_Auth.rx_userAccount,
  }))(HorizontalMenu),
);
