import { FileUploadProps, ID } from 'definitions/constants-fe';
import { TAdminFile } from 'definitions/types-fe';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/remote-config';
import 'firebase/compat/storage';
import { DocumentData, QuerySnapshot } from 'firebase/firestore';
import {
  convertUrlToBlob,
  getFileName,
  isImageExtention,
} from 'library/fileHelper';
import { Reporting } from 'library/sentry/reporting';
import ReduxSagaFirebase from 'redux-saga-firebase';
import { notEmpty } from '../../../../shared/snippets';
import { IStorageFile } from '../../../../shared/types';

const config = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
};

class Firebase {
  database;
  remoteConfig;
  storageRef;
  firstAppAuth;
  secondAppAuth;
  rsf;
  rsfFirestore;
  refFireFunction;
  EmailAuthProvider;
  FieldValue;
  FieldPath;
  Timestamp;

  constructor() {
    const firebaseApp = firebase.initializeApp(config);
    const secondApp = firebase.initializeApp(config, 'secondApp');

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.database = firebase.firestore();
    this.remoteConfig = firebaseApp.remoteConfig();
    this.storageRef = firebaseApp.storage().ref();
    this.firstAppAuth = firebaseApp.auth();
    this.secondAppAuth = secondApp.auth();
    this.rsf = new ReduxSagaFirebase(firebaseApp);
    this.rsfFirestore = this.rsf.firestore;
    this.refFireFunction = firebaseApp.functions(
      process.env.REACT_APP_CLOUD_FUNCTION_LOCATION,
    );
    this.EmailAuthProvider = firebase.auth.EmailAuthProvider;
    this.FieldValue = firebase.firestore.FieldValue;
    this.FieldPath = firebase.firestore.FieldPath;
    this.Timestamp = firebase.firestore.Timestamp;

    this.activateRemoteConfig();
  }

  activateRemoteConfig = async () => {
    this.remoteConfig.settings = {
      fetchTimeoutMillis: 60 * 1000, // Needed as otherwise it crashes on Firefox
      minimumFetchIntervalMillis:
        process.env.NODE_ENV === 'production' ? 60 * 1000 : 0,
    };
    await this.remoteConfig.fetchAndActivate();
  };

  updateRemoteConfig = async () => {
    this.remoteConfig.settings = {
      fetchTimeoutMillis: 60 * 1000, // Needed as otherwise it crashes on Firefox
      // Instantly
      minimumFetchIntervalMillis: 0,
    };
    await this.remoteConfig.fetchAndActivate();
  };

  waitUntilRemoteConfigReady = async () => {
    await this.remoteConfig.ensureInitialized();
  };

  createBatch = () => {
    return this.database.batch();
  };

  async login(provider: string, info: any, remember_me = false) {
    const persistence = remember_me
      ? firebase.auth.Auth.Persistence.LOCAL
      : firebase.auth.Auth.Persistence.SESSION;
    await this.firstAppAuth.setPersistence(persistence);
    switch (provider) {
      case ID.email:
        return this.firstAppAuth.signInWithEmailAndPassword(
          info.email,
          info.password,
        );
      default:
        break;
    }
  }

  sendVerificationEmail() {
    if (this.firstAppAuth.currentUser == null) {
      Reporting.Error(
        new Error(`sendVerificationEmail currentUser is unexpectedly null.`),
      );
    }
    return this.firstAppAuth.currentUser?.sendEmailVerification();
  }

  logout() {
    return this.firstAppAuth.signOut();
  }

  getAuthToken = async () => {
    try {
      const token = await this.firstAppAuth.currentUser?.getIdToken();
      return token;
    } catch (err) {
      Reporting.Error(err);
      return null;
    }
  };

  resetPassword(email: string) {
    return this.firstAppAuth.sendPasswordResetEmail(email);
  }

  snapshotAsObject(snapshot: QuerySnapshot<DocumentData>) {
    let data = {};
    snapshot.forEach((doc) => {
      data = {
        ...data,
        [doc.id]: doc.data(),
      };
    });
    return data;
  }

  snapshotAsArray(
    snapshot: QuerySnapshot<DocumentData>,
    withKeyField: boolean = false,
  ) {
    if (withKeyField) {
      return snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });
    }
    return snapshot.docs.map((x) => x.data());
  }

  getFiles = async (
    files: IStorageFile[],
    getThumbUrl = true,
  ): Promise<TAdminFile[]> => {
    const fileRecords = files.map(async (file: IStorageFile, index: number) => {
      const pathExt = file.name.split('.').pop();
      let mainFile = '';
      try {
        mainFile = await this.storageRef.child(file.path).getDownloadURL();
      } catch (exception) {
        return null;
      }
      const fileObject: TAdminFile = {
        mainUrl: mainFile,
        thumbUrl: '',
        name: file.name,
        fullName: file.path,
        uid: 'rc-upload-' + new Date().getTime() + index,
      };
      if (pathExt !== 'pdf' && getThumbUrl && file.thumb) {
        try {
          fileObject.thumbUrl = await this.storageRef
            .child(file.thumb)
            .getDownloadURL();
        } catch (exception) {
          fileObject.thumbUrl = mainFile;
        }
      } else {
        fileObject.thumbUrl = mainFile;
      }
      if (file.id) {
        fileObject.id = file.id;
      }
      return fileObject;
    });

    const res = await Promise.all(fileRecords);
    return res.filter(notEmpty);
  };

  uploadFilePromise = async (base: string, fileName: string, file: Blob) => {
    return new Promise((resolve, reject) => {
      const uploadTask = this.storageRef.child(`${base}/${fileName}`).put(file);
      uploadTask.on(
        ID.state_changed,
        () => {
          // any required action
        },
        function error(err) {
          Reporting.Error(err);
          reject();
        },
        function complete() {
          resolve(true);
        },
      );
    });
  };

  adminFilesToStorageFiles = async (
    storagePath: string,
    filesArray: TAdminFile[],
    otherProps = FileUploadProps,
  ): Promise<IStorageFile[]> => {
    const records = filesArray.map(async (file: TAdminFile, index: number) => {
      let fileName = getFileName(file.name, index);
      const extention = fileName.split('.').pop()?.toLowerCase() ?? '-';
      const string =
        file.fullName &&
        file.fullName.indexOf(
          `${
            otherProps.imageFromDifferentFolder
              ? otherProps.imageFromDifferentFolder
              : storagePath
          }/`,
        ) !== -1;
      let fileObj: IStorageFile = {
        name: '',
        path: '',
      };
      if (string && !file.isEdited) {
        if (otherProps.isCopy || otherProps.imageFromDifferentFolder) {
          const blob = await convertUrlToBlob(file.mainUrl);
          await this.uploadFilePromise(storagePath, fileName, blob);
          fileObj = { name: file.name, path: `${storagePath}/${fileName}` };
        } else {
          fileObj = { name: file.name, path: file.fullName };
        }
      } else if (file.originFileObj != null) {
        if (otherProps.useFileName) {
          fileName = otherProps.useFileName;
        }
        await this.uploadFilePromise(storagePath, fileName, file.originFileObj);
        fileObj = {
          name: file.name,
          path: `${storagePath}/${fileName}`,
        };
      }
      if (file.id) {
        fileObj.id = file.id;
      }
      if (otherProps.addUid && file.id == null) {
        fileObj.id = file.uid;
      }
      if (isImageExtention(extention)) {
        const thumb =
          otherProps.isCopy || otherProps.imageFromDifferentFolder
            ? fileName
            : fileObj.path.split('/').pop();
        fileObj.thumb = `${storagePath}_thumb/thumb_${thumb}`;
      }
      return fileObj;
    });
    return Promise.all(records);
  };

  loadSingleFile = async (path: string, name: string, fullName: string) => {
    const url = await this.storageRef.child(path).getDownloadURL();
    const mainFile: TAdminFile = {
      fullName: fullName,
      mainUrl: url,
      name: name,
      thumbUrl: url,
      uid: 'rc-upload-' + new Date().getTime(),
    };
    return mainFile;
  };

  getRemoteConfigString = (key: string) => {
    return this.remoteConfig.getString(key);
  };
}

export default new Firebase();
