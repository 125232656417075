const theme: any = {};

theme.palette = {
  primary: [
    '#4482FF', // 0: Default
    '#3A78F5', // 1: Darken 4%
    '#3775F2', // 2: Darken 5%
    'rgba(68, 130, 255, 0.2)', // 3: Fade 20%
    '#4C8AFF', // 4: Lighten 3%
    'rgba(68, 130, 255, 0.75)', // 5: Fade 75%
    '#6AA8FF', // 6: Lighten 15%
    '#63A1FF', // 7: Lighten 12%
    '#3F7DFA', // 8: Darken 2%
    '#3369e7', // 9: Algolia color
    '#5896FF', // 10: Lighten 8%
    '#2b69e6', // 11:
    '#236cfe', // 12: darken 10%
    '#4d88ff', // 13: Lighten 5%
    '#e6f7ff', // 14: Theme hover color light
    '#40a9ff', // 15: Theme hover color dark
  ],
  secondary: [
    '#2d3446', // 0: DarkBlue
    '#f1f3f6', // 1: LightBluish
    '#788195', // 2: LightBlue
    '#E4E6E9', // 3: LightBluish Darken 5%
    '#364d79', // 4:
    '#202739', // 5: DarkBlue Darken 5%
    '#f5f6f8', // 6: LighterBluish
    '#e9ebf1', // 7: DarkBluish
    '#F7F9FB', // 8: LighterBluish Lighten 4%
    '#E9EBEE', // 9: LighterBluish Darken 3%
    '#1a1a1a', // 10: Sidebar submenu select
  ],
  color: [
    '#FEAC01', // 0: Orange
    '#42299a', // 1: Purple
    '#F75D81', // 2: Pink
    '#7ED321', // 3: LimeGreen
    '#39435f', // 4: BlueShade
    '#FFCA28', // 5: Yellow
    '#F2BD1B', // 6: Yellow Darken 5%
    '#3b5998', // 7: Facebook
    '#344e86', // 8: Facebook Darken 5%
    '#dd4b39', // 9: Google Plus
    '#d73925', // 10: Google Plus Darken 5%
    '#e14615', // 11: Auth0
    '#ca3f13', // 12: Auth0
    '#e0364c', // 13: themeColor--AlizarinCrimson
  ],
  warning: [
    '#ffbf00', // 0: Warning light
    '#ff9800', // 1: Warning dark
  ],
  success: [
    '#00b16a', // 0: Success
  ],
  error: [
    '#f64744', // 0: Error
    '#EC3D3A', // 1: Darken 4%
    '#FF5B58', // 2: Lighten 8%
    '#ffe8ed', // 3: Questionnaire background error color
  ],
  grayscale: [
    '#bababa', // 0: GreyShade
    '#c1c1c1', // 1: GreyDark
    '#D8D8D8', // 2: Grey
    '#f1f1f1', // 3: GreyAlt
    '#F3F3F3', // 4: GreyLight
    '#fafafa', // 5: DarkWhite
    '#F9F9F9', // 6: DarkerWhite
    '#fdfdfd', // 7: #fff Darken 1%
    '#eeeeee', // 8:
    '#fbfbfb', // 9:
    '#f5f5f5', // 10:
    '#f7f8f9', // 11: today-highlight-bg
    '#e5e5e5', // 12:
    '#fcfcfc', // 13:
    '#ececec', // 14:
  ],
  text: [
    '#323332', // 0: Heading
    '#595959', // 1: HeadingLight
    '#979797', // 2: Text
    '#797979', // 3: TextDark
    '#6a6c6a', // 4: Heading Lighten 22%
    '#aaaaaa', // 5: SubLabelText
    '#bbbbbb', // 6: LightText
  ],
  border: [
    '#e9e9e9', // 0: Border
    '#d8d8d8', // 1: BorderDark
    '#ebebeb', // 2: BorderLight
    '#d3d3d3', // 3:
    'rgba(228, 228, 228, 0.65)', // 4:
    'rgba(136, 136, 136, 0.45)', // 5: box-shadow for questionnaire scrollable div
  ],

  calendar: [
    '#905', // 0:
    '#690', // 1:
    '#a67f59', // 2:
    '#07a', // 3:
    '#dd4a68', // 4:
    '#e90', // 5:
  ],
  custom: [
    '#ffffff', // 0 : Table header background(other basics too)
    '#5293d3', //1 : Primary button color
    '#f1f1f1', //2 : Search text-box
    '#565656', //3 : Page title(main)
    '#9e9e9e', //4 : Page sub-title
    '#797979', //5 : Table title
    '#ebeff2', //6 : Table border
    '#6c757d', //7 : Table font color
    '#087e87', //8 : Menu backgound color
    '#212529', //9 : Table header font color
    '#dddddd', //10 : Desktop menu font default color
    '#7f8b91', //11 : Card-view delete icon,
    '#1bbc9b', //12 : Tag color(green)
    '#f1f5f7', //13 : Tag color(grey)
    '#d9d9d9', //14 : Breadcrumb background
    '#ff0000', //15 : Error Form fields
    '#aaaaaa', //16 : breadcrumbs
    '#99d25e', //17 : property copy button hover
    '#4285F4', //18 : component card value blue color
    '#33B5E5', //19 : component card value cyan color
    '#20b2aa', //20 : component card value light sea green color
    '#FF8800', //21 : component card value orange color
    '#488fe1', //22 : process screen files listing color
    '#faad14', //23 : all-site notification color
    '#f7f7f7', //24 : image background color
    '#5a5f63', //25 : immoMove start screen text color
  ],
  status: [
    '#d81150', // 0 : Red text
    '#ffdce4', // 1 : Red background
    '#333333', // 2 : Grey text
    '#eeeeee', // 3 : Grey background
    '#9a8b00', // 4 : Yellow text
    '#fff8ab', // 5 : Yellow background
    '#b75500', // 6 : Orange text
    '#ffe6ae', // 7 : Orange background
    '#179801', // 8 : Green text
    '#d8f7ca', // 9 : Green background
    '#008080', //10 : Cyan text
    '#d1f5eb', //11 : Cyan background
    '#0162af', //12 : Blue text
    '#c7deff', //13 : Blue background
    '#5319bb', //14 : Purple text
    '#e0d0ff', //15 : Purple background
    '#b3047d', //16 : Pink text
    '#ffcbea', //17 : Pink background
    '#ffffff', //18 : Gray inverted text
    '#444444', //19 : Gray inverted background
  ],
  actionButton: [
    'rgba(0, 0, 0, 0.65)', // 0 : Edit icon text
    '#d9d9d9', // 1 : Edit icon border
  ],
  tool: [
    '#087e87', // 0 : ImmoApp
    '#008cff', // 1 : ImmoFonds
    '#f12e45', // 2 : ImmoMove
    '#777777', // 3 : Admin
  ],
  signInScreens: [
    '#5e85b4', // 0 : ImmoTools button Color
    '#767676', // 1 : Footer text color
    '#505050', // 2 : Footer text hover color
    '#7497c1', // 3 : ImmoTools button hover Color
    '#ef1831', // 4 : ImmoMove button hover color
  ],
  immoAppCharts: [
    '#cccccc', //0 :
    '#444', //1
    '#777', //2
    '#44BB44', //3
    '#DD4444', //4
    '#333333', //5
    'rgba(0,0,0,0)', //6
    'rgba(0, 0, 0, 0.5)', //7
    '#FF0000', //8
  ],
  immoMoveCharts: [
    '#95de64', // 0 : Green
    '#ff7875', // 1 : Red
    '#aaaaaa', // 2 : Gray
  ],
};

theme.fonts = {
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
};

theme.sizes = {
  max_xs: '(max-width: 479px)',
  min_xs: '(min-width: 480px)',
  max_sm: '(max-width: 575px)',
  min_sm: '(min-width: 576px)',
  max_md: '(max-width: 767px)',
  min_md: '(min-width: 768px)',
  max_lg: '(max-width: 991px)',
  min_lg: '(min-width: 992px)',
  max_xl: '(max-width: 1199px)',
  min_xl: '(min-width: 1200px)',
};

export default theme;
