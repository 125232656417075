import { ReactComponent as document } from 'assets/images/document.svg';
import { ReactComponent as inbox } from 'assets/images/inbox.svg';
import { ReactComponent as mandate } from 'assets/images/mandate.svg';
import { ReactComponent as pin } from 'assets/images/pin.svg';
import { ReactComponent as home } from 'assets/images/simple-house.svg';
import { ReactComponent as user } from 'assets/images/user.svg';
import { ReactComponent as vote } from 'assets/images/vote.svg';
import { ReactComponent as wrench } from 'assets/images/wrench.svg';
import Loader from 'components/Utility/loader';
import { getToken, getUserRole } from 'library/localStorageHelper';
import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PAGE, PUBLIC_ROUTE, TOOL } from 'route.constants';
import { ACCESS_RULES } from '../../../../../shared/constants';
import { TRole } from '../../../../../shared/types';

interface IRoute {
  path: string;
  component: any;
  label: string;
  icon: any | null;
  isMenuItem: boolean;
  access: (role: TRole, extra?: boolean) => boolean;

  exact?: boolean;
}

export const ROUTES: IRoute[] = [
  {
    path: `/${TOOL.STATISTICS}`,
    component: lazy(() => import('containers/global/Statistics/Statistics')),
    isMenuItem: false,
    label: '',
    icon: null,
    access: (role: TRole) => ACCESS_RULES.general_manager.includes(role),
  },
  {
    path: `/${TOOL.SETTINGS}`,
    component: lazy(() => import('containers/global/Settings/Settings')),
    isMenuItem: false,
    label: '',
    icon: null,
    access: (role: TRole) => ACCESS_RULES.general_manager.includes(role),
  },
  {
    path: `/${TOOL.IMMOAPP}/${PAGE.IA_DASHBOARD}`,
    component: lazy(() => import('containers/immoapp/Dashboard/Dashboard')),
    isMenuItem: true,
    label: 'global.home',
    icon: home,
    access: (role: TRole) =>
      ACCESS_RULES.adminapp.includes(role) &&
      process.env.REACT_APP_IA === String(true),
  },
  {
    path: `/${TOOL.IMMOAPP}/${PAGE.IA_MANDATES}/:mandateId`,
    component: lazy(
      () => import('containers/immoapp/Mandates/MandatesDetails'),
    ),
    isMenuItem: false,
    label: '',
    icon: null,
    access: (role: TRole) =>
      ACCESS_RULES.general_manager.includes(role) &&
      process.env.REACT_APP_IA === String(true),
  },
  {
    path: `/${TOOL.IMMOAPP}/${PAGE.IA_MESSAGES}`,
    component: lazy(() => import('containers/immoapp/Messages/Messages')),
    isMenuItem: true,
    label: 'global.messages',
    icon: inbox,
    access: (role: TRole) =>
      ACCESS_RULES.adminapp.includes(role) &&
      process.env.REACT_APP_IA === String(true),
  },
  {
    path: `/${TOOL.IMMOAPP}/${PAGE.IA_REPAIRS}`,
    component: lazy(() => import('containers/immoapp/Repairs/Repairs')),
    isMenuItem: true,
    label: 'global.repairs',
    icon: wrench,
    access: (role: TRole) =>
      ACCESS_RULES.adminapp.includes(role) &&
      process.env.REACT_APP_IA === String(true),
  },
  {
    path: `/${TOOL.IMMOAPP}/${PAGE.IA_PINBOARD}`,
    component: lazy(() => import('containers/immoapp/Pinboard/Pinboard')),
    isMenuItem: true,
    label: 'global.pinboard',
    icon: pin,
    access: (role: TRole, extra?: boolean) =>
      extra === true &&
      ACCESS_RULES.adminapp.includes(role) &&
      process.env.REACT_APP_IA === String(true),
  },
  {
    path: `/${TOOL.IMMOAPP}/${PAGE.IA_DOCUMENTS}`,
    component: lazy(() => import('containers/immoapp/Documents/Documents')),
    isMenuItem: true,
    label: 'global.documents',
    icon: document,
    access: (role: TRole, extra?: boolean) =>
      extra === true &&
      ACCESS_RULES.adminapp.includes(role) &&
      process.env.REACT_APP_IA === String(true),
  },
  {
    path: `/${TOOL.IMMOAPP}/${PAGE.IA_VOTES}`,
    component: lazy(() => import('containers/immoapp/Votes/Votes')),
    exact: true, // needed so that subpage works
    isMenuItem: true,
    label: 'global.votes',
    icon: vote,
    access: (role: TRole) =>
      ACCESS_RULES.voting_admin.includes(role) &&
      process.env.REACT_APP_IA === String(true),
  },
  {
    path: `/${TOOL.IMMOAPP}/${PAGE.IA_VOTES}/:value`,
    component: lazy(() => import('containers/immoapp/Votes/VoteDetails')),
    isMenuItem: false,
    label: '',
    icon: null,
    access: (role: TRole) =>
      ACCESS_RULES.voting_admin.includes(role) &&
      process.env.REACT_APP_IA === String(true),
  },
  {
    path: `/${TOOL.IMMOAPP}/${PAGE.IA_MANDATES}`,
    component: lazy(() => import('containers/immoapp/Mandates/Mandates')),
    exact: true, // needed so that subpage works
    isMenuItem: true,
    label: 'GLOBAL.MANDATES',
    icon: mandate,
    access: (role: TRole) =>
      ACCESS_RULES.general_manager.includes(role) &&
      process.env.REACT_APP_IA === String(true),
  },
  {
    path: `/${TOOL.IMMOAPP}/${PAGE.IA_USERS}`,
    component: lazy(() => import('containers/immoapp/Users/Users')),
    isMenuItem: true,
    label: 'global.user',
    icon: user,
    access: (role: TRole) =>
      ACCESS_RULES.general_manager.includes(role) &&
      process.env.REACT_APP_IA === String(true),
  },
  {
    path: `/${TOOL.IMMOFONDS}/${PAGE.IF_HOME}`,
    component: lazy(() => import('containers/immofonds/Home/Home')),
    isMenuItem: false,
    label: '',
    icon: null,
    access: (role: TRole) =>
      ACCESS_RULES.general_manager.includes(role) &&
      process.env.REACT_APP_IF === String(true),
  },
  {
    path: `/${TOOL.IMMOFONDS}/${PAGE.IF_IMMOVABLE}/:value`,
    component: lazy(() => import('containers/immofonds/Immovable/Immovable')),
    isMenuItem: false,
    label: '',
    icon: null,
    access: (role: TRole) =>
      ACCESS_RULES.general_manager.includes(role) &&
      process.env.REACT_APP_IF === String(true),
  },
  {
    path: `/${TOOL.IMMOFONDS}/${PAGE.IF_COMPONENT}/:value`,
    component: lazy(() => import('containers/immofonds/Component/Component')),
    isMenuItem: false,
    label: '',
    icon: null,
    access: (role: TRole) =>
      ACCESS_RULES.general_manager.includes(role) &&
      process.env.REACT_APP_IF === String(true),
  },
  {
    path: `/${TOOL.IMMOMOVE}/${PAGE.IM_PROCESSES}`,
    component: lazy(() => import('containers/immomove/Home/Home')),
    exact: true, // needed so that subpage works
    isMenuItem: false,
    label: '',
    icon: null,
    access: (role: TRole) =>
      ACCESS_RULES.general_manager.includes(role) &&
      process.env.REACT_APP_IM === String(true),
  },
  {
    path: `/${TOOL.IMMOMOVE}/${PAGE.IM_PROCESSES}/:value`,
    component: lazy(() => import('containers/immomove/Process/Process')),
    isMenuItem: false,
    label: '',
    icon: null,
    access: (role: TRole) =>
      ACCESS_RULES.general_manager.includes(role) &&
      process.env.REACT_APP_IM === String(true),
  },
  {
    path: `/${TOOL.IMMOMOVE}/${PAGE.IM_START}`,
    component: lazy(() => import('containers/immomove/Tenant/Start/Start')),
    isMenuItem: false,
    label: '',
    icon: null,
    access: () => process.env.REACT_APP_IM === String(true),
  },
  {
    path: `/${TOOL.IMMOMOVE}/${PAGE.IM_QUESTIONNAIRE}`,
    component: lazy(
      () => import('containers/immomove/Tenant/Questionnaire/Questionnaire'),
    ),
    isMenuItem: false,
    label: '',
    icon: null,
    access: () => process.env.REACT_APP_IM === String(true),
  },
  {
    path: `/${TOOL.IMMOMOVE}/${PAGE.IM_END}`,
    component: lazy(() => import('containers/immomove/Tenant/End/End')),
    isMenuItem: false,
    label: '',
    icon: null,
    access: () => process.env.REACT_APP_IM === String(true),
  },
];

export const routesExtraField = (
  ele: IRoute,
  rx_remoteConfigAccessPinboard: boolean,
  rx_remoteConfigAccessDocuments: boolean,
) => {
  if (ele.path.includes(PAGE.IA_PINBOARD)) {
    return rx_remoteConfigAccessPinboard;
  }
  if (ele.path.includes(PAGE.IA_DOCUMENTS)) {
    return rx_remoteConfigAccessDocuments;
  }
  return false;
};

interface ClassPropsDirect {
  rx_remoteConfigAccessPinboard: boolean;
  rx_remoteConfigAccessDocuments: boolean;
}

export default function AppRouter({
  rx_remoteConfigAccessPinboard,
  rx_remoteConfigAccessDocuments,
}: ClassPropsDirect) {
  const isAuthenticated = getToken() != null;
  const userRole = getUserRole(false);

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {ROUTES.map((ele, index) => (
          <Route exact={ele.exact} key={index} path={ele.path}>
            {isAuthenticated &&
            ele.access(
              userRole ?? '-',
              routesExtraField(
                ele,
                rx_remoteConfigAccessPinboard,
                rx_remoteConfigAccessDocuments,
              ),
            ) ? (
              <ele.component />
            ) : (
              <Redirect
                to={{
                  pathname: isAuthenticated
                    ? PUBLIC_ROUTE.PAGE_403
                    : PUBLIC_ROUTE.LANDING,
                }}
              />
            )}
          </Route>
        ))}
      </Switch>
    </Suspense>
  );
}
