import { Result } from 'antd';
import SpinnerFullscreen from 'components/SpinnerFullscreen';
import { translate } from 'components/Utility/i18n';
import ActionButtons from 'containers/global/Page/ActionButtons';
import { Reporting } from 'library/sentry/reporting';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const chunkFailedMessage = /Loading (CSS )?chunk [\d]+ failed/;

class ErrorScreen extends React.Component<
  RouteComponentProps<any>,
  { error: any; lastRefreshTime: number }
> {
  public constructor(props: RouteComponentProps<any>) {
    super(props);
    this.state = { error: null, lastRefreshTime: 0 };
  }

  public static getDerivedStateFromError(error: unknown) {
    return { error: error };
  }

  public render() {
    const { error, lastRefreshTime } = this.state;

    if (error) {
      if (error.message && chunkFailedMessage.test(error.message)) {
        if (Date.now() - lastRefreshTime > 10000) {
          this.setState(
            {
              lastRefreshTime: Date.now(),
            },
            () => {
              window.location.reload();
            },
          );
        }
        return <SpinnerFullscreen />;
      }

      Reporting.Error(
        `Error on page ${this.props.history.location.pathname}: ${error}.`,
      );
      return (
        <Result
          className="max-w-[500px] mx-auto my-0"
          status="500"
          title="500"
          subTitle={translate('error.page500message')}
          extra={
            <ActionButtons
              backExtraHandler={() => {
                this.setState({ error: null });
              }}
              logoutExtraHandler={() => {
                this.setState({ error: null });
              }}
            />
          }
        />
      );
    }
    return this.props.children;
  }
}

export default withRouter(ErrorScreen);
