import i18n from 'i18next';
import { Reporting } from 'library/sentry/reporting';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LANGUAGE_ID } from '../../../../shared/constants';
import { TRANSLATIONS_DE } from '../../../../shared/i18n/de';
import { TRANSLATIONS_EN } from '../../../../shared/i18n/en';
import { TRANSLATIONS_FR } from '../../../../shared/i18n/fr';
import { TRANSLATIONS_IT } from '../../../../shared/i18n/it';

i18n.use(initReactI18next).init({
  lng: DEFAULT_LANGUAGE_ID, // Default language
  fallbackLng: DEFAULT_LANGUAGE_ID, // Fallback language
  keySeparator: false, // Necessary for flat JSON structure
  resources: {
    de: {
      translation: TRANSLATIONS_DE,
    },
    en: {
      translation: TRANSLATIONS_EN,
    },
    fr: {
      translation: TRANSLATIONS_FR,
    },
    it: {
      translation: TRANSLATIONS_IT,
    },
  },
  interpolation: {
    escapeValue: false,
  },
  saveMissing: true,
  missingKeyHandler: (lngs: readonly string[], ns: string, key: string) => {
    Reporting.Warning(
      `Translation is missing. Lang: ${lngs}. Ns: ${ns}. Key: ${key}.`,
    );
  },
});

export const translate = (key: string, options?: Record<string, any>) =>
  // Currently, this throws an error, but it works perfectly fine.
  // @ts-ignore
  i18n.t(key, options);

export const changeLanguage = (id: string) => i18n.changeLanguage(id);

export default i18n;
